<template>
  <div>
    <v-card
      class="tw-p-4 tw-mt-4 tw-relative"
      outlined
      v-for="(notification, index) in modifiedNotifications"
      :key="`notif-${notification.id}`"
    >
      <UiNotificationEdit
        :notification="notification"
        :isLocatedForm="isLocatedForm"
        @edit="ruleEditInProgress = !ruleEditInProgress"
        @save="saveNotification(index, $event)"
        @delete="deleteNotification(index)"
      />
    </v-card>

    <div class="tw-text-center tw-mt-4">
      <v-btn :disabled="ruleEditInProgress" text color="primary" @click="addNewRule">
        <v-icon>{{ icons.mdiPlus }}</v-icon>
        <span>{{ $t(`forms.individual.notifications.internal.email.edit.add.button`) }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import UiNotificationEdit from '@/components/Forms/Individual/UI/NotificationEdit.vue'
import { mdiPlus } from '@mdi/js'

export default {
  name: 'UiNotificationList',
  components: {
    UiNotificationEdit,
  },
  model: {
    prop: 'notifications',
  },
  props: {
    notifications: {
      type: Array,
      required: true,
    },
    isLocatedForm: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    modifiedNotifications: [],
    ruleEditInProgress: false,
    icons: {
      mdiPlus,
    },
  }),
  created() {
    this.modifiedNotifications = this.notifications.map((notification, index) => {
      return { ...notification, id: index }
    })
  },
  methods: {
    addNewRule() {
      this.ruleEditInProgress = true
      this.modifiedNotifications.push({
        id: this.modifiedNotifications[this.modifiedNotifications.length - 1]?.id + 1 || 1,
        recipients: [],
      })
    },
    saveNotification(index, notification) {
      this.ruleEditInProgress = false
      this.modifiedNotifications[index] = { ...notification }

      this.$emit('input', this.modifiedNotifications)
    },
    deleteNotification(index) {
      if (this.modifiedNotifications.length === index + 1) this.ruleEditInProgress = false
      this.modifiedNotifications.splice(index, 1)

      this.$emit('input', this.modifiedNotifications)
    },
  },
}
</script>
