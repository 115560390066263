<template>
  <div class="ui-input-tabbed-list">
    <div class="tw-flex">
      <div class="tw-text-xs tw-flex tw-items-center">{{ title }}</div>
      <v-tabs v-model="currentInputTab" right @change="changeTab">
        <v-tab v-for="tab in tabs" :key="tab">
          {{ tab }}
          <div class="ui-input-tabbed-list__empty-tab" v-if="shouldDisplayBadge(tab)"></div>
        </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items v-model="currentInputTab">
      <v-tab-item v-for="tab in tabs" :key="tab">
        <!-- TODO: handle open status correctly -->
        <v-expansion-panels accordion :value="pannelOpened ? 0 : null" solo outlined dense hide-details="auto">
          <v-expansion-panel class="ui-input-tabbed-list__element" @change="togglePanel">
            <v-expansion-panel-header class="tw-px-3 tw-py-2 ui-input-tabbed-list__element__header">
              <div class="tw-text-sm">{{ placeholder }}</div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <div class="tw-grid tw-grid-cols-2" v-for="currentElement in modifiedContent" :key="currentElement.value">
                <UiInputTextField
                  v-model="currentElement.key"
                  class="ui-input-tabbed-list__element__content tw-mr-0"
                  disabled
                  :outlined="false"
                  :rounded="false"
                />
                <UiInputTextField
                  v-model="currentElement.label[tab]"
                  class="ui-input-tabbed-list__element__content tw-mr-0"
                  @input="emitInput($event, currentElement.key, tab)"
                  :outlined="false"
                  :rounded="false"
                />
              </div>
              <!-- :title="$t(`forms.individual.fields.labels.label`)" -->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import UiInputTextField from '@/components/UI/input/InputTextField.vue'
import { clone } from '@/utils/utilities.util'

export default {
  name: 'UiInputTabbedList',
  components: {
    UiInputTextField,
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    defaultContent: {
      type: Array,
      required: false,
      default: null,
    },
    currentTab: {
      type: Number,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      currentInputTab: null,
      modifiedContent: null,
      pannelOpened: false,
    }
  },
  created() {
    this.modifiedContent = clone(this.defaultContent)
    this.currentInputTab = clone(this.currentTab)
  },
  methods: {
    emitInput(value, key, tab) {
      if (value === '') {
        const modifiedIndex = this.modifiedContent.findIndex(content => content.key === key)
        this.modifiedContent[modifiedIndex].label[tab] = null
      }
      this.$emit('input', this.modifiedContent)
    },
    changeTab(tab) {
      this.$emit('changeTab', tab)
    },
    shouldDisplayBadge(tab) {
      if (this.required) {
        return !this.modifiedContent[tab]
      }

      const modifiedTabContent = this.modifiedContent.map(content => content.label[tab] || null)
      return modifiedTabContent.some(content => !content)
    },
    togglePanel() {
      this.pannelOpened = !this.pannelOpened
    },
  },
  watch: {
    currentTab: {
      handler(newTab, oldTab) {
        if (newTab !== oldTab) this.currentInputTab = newTab
      },
      immediate: false,
    },
  },
}
</script>

<style lang="scss">
.ui-input-tabbed-list {
  &__empty-tab {
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background: red;
  }
  .v-tab {
    min-width: 0 !important;
  }

  .v-tabs-bar {
    height: 24px !important;
  }

  &__element {
    border: solid 1px rgba(229, 231, 235, var(--tw-border-opacity));

    &__header {
      min-height: 40px !important;
    }
    &__content {
      border: solid 0.5px rgba(229, 231, 235, var(--tw-border-opacity));
      border-left: none;
    }
    &__content:nth-child(even) {
      border-right: none;
    }
    &__content:last-child,
    &__content:nth-last-child(2) {
      border-bottom: none;
    }

    .v-expansion-panel-content__wrap {
      padding: 0 !important;
    }
  }
}
</style>
