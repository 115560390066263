<template>
  <!-- GTR Lead-Gen Widget - Start -->
  <gtr-form-widget
    :id="id"
    :form-id="id"
    :locale="locale"
    :dark="dark"
    :font="font"
    :color="color"
    :css-url="cssUrl"
    :radius="radius"
    :font-size="fontSize"
    :dense="dense"
    :outlined="outlined"
    :flat="flat"
    :clearable="clearable"
    class="tw-w-full"
  ></gtr-form-widget>
  <!-- GTR Lead-Gen Widget - End -->
</template>

<script>
import { loadScript } from '@/utils/formWidget.util'

export default {
  name: 'UiFormWidget',

  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    locale: {
      type: String,
      required: false,
      default: null,
    },
    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
    font: {
      type: String,
      required: false,
      default: null,
    },
    color: {
      type: String,
      required: false,
      default: null,
    },
    cssUrl: {
      type: String,
      required: false,
      default: null,
    },
    fontSize: {
      type: String,
      required: false,
      default: null,
    },
    radius: {
      type: String,
      required: false,
      default: null,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    flat: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  mounted() {
    loadScript()
  },
}
</script>
