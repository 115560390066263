<template>
  <UiCardForm class="forms-individual-notifications-email">
    <!-- <v-col class="forms-individual-notifications-email__frame" cols="7">
        <div class="forms-individual-notifications-email__frame__content">
          <iframe-form />
        </div>
      </v-col> -->
    <div class="tw-mb-2 tw-font-semibold tw-text-lg">
      {{ $t('forms.individual.notifications.email.title') }}
    </div>
    <div class="tw-flex tw-flex-col">
      <div>
        <div class="tw-text-xs tw-text-gray-500 tw-flex">
          {{ $t(`forms.individual.notifications.email.brandColor.label`) }}
        </div>
        <ui-input-color-picker
          id="brand-color-picker"
          :current-color="modifiedLayout.primaryColor"
          :placeholder="$t('forms.individual.notifications.email.brandColor.placeholder')"
          @colorUpdate="color => (modifiedLayout.primaryColor = color)"
        />
      </div>
      <div>
        <div class="tw-text-xs tw-text-gray-500 tw-flex">
          {{ $t(`forms.individual.notifications.email.textColor.label`) }}
        </div>
        <ui-input-color-picker
          id="text-color-picker"
          :current-color="modifiedLayout.altTextColor"
          :placeholder="$t('forms.individual.notifications.email.textColor.placeholder')"
          @colorUpdate="color => (modifiedLayout.altTextColor = color)"
        />
      </div>

      <div class="tw-mb-4">
        <div class="tw-text-xs tw-text-gray-500 tw-flex tw-my-1">
          {{ $t(`forms.individual.notifications.email.logo.label`) }}
        </div>
        <ui-input-file
          class="tw-mt-1"
          :is-loading="isLoadingFile"
          :fileUrl="getFileUrl"
          @change="uploadFile"
          @deleteFile="deleteFile"
        />
      </div>

      <div class="tw-flex tw-flex-col">
        <UiInputTextField
          v-model="modifiedLayout.clientName"
          :label="$t('forms.individual.notifications.email.footerName.label')"
          :placeholder="$t('forms.individual.notifications.email.footerName.placeholder')"
        ></UiInputTextField>
      </div>
    </div>
  </UiCardForm>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import UiInputFile from '@/components/UI/input/File.vue'
import UiCardForm from '@/components/UI/CardForm.vue'
import UiInputTextField from '@/components/UI/input/InputTextField.vue'
import UiInputColorPicker from '@/components/UI/input/ColorPicker.vue'
import { clone } from '@/utils/utilities.util'

import { mdiContentSaveCheckOutline } from '@mdi/js'
import { validationMixin } from 'vuelidate'
import { uploadFile } from '@/services/leads.service'

export default {
  name: 'FormsIndividualNotificationsConfigurations',
  components: {
    UiInputFile,
    UiInputColorPicker,
    UiCardForm,
    UiInputTextField,
  },
  mixins: [validationMixin],
  props: {
    layout: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiContentSaveCheckOutline,
      },
      isLoadingFile: false,
      file: null,
      modifiedLayout: null,
      initialLayout: null,
    }
  },
  created() {
    this.modifiedLayout = { ...this.layout }
    this.initialLayout = clone(this.modifiedLayout)
  },
  watch: {
    modifiedLayout: {
      handler(current, previous) {
        this.$emit('contentModified', this.hasEditedContent, 'notifications')
        if (current !== previous) {
          this.$emit('updateLayout', current)
        }
      },
      deep: true,
      immediate: false,
    },
  },
  computed: {
    ...mapState({
      currentForm: state => state.leads.currentForm,
    }),
    getFileUrl() {
      return this.modifiedLayout.logo?.url
    },
    hasEditedContent() {
      return JSON.stringify(this.modifiedLayout) != JSON.stringify(this.initialLayout)
    },
  },
  methods: {
    ...mapActions({
      setAlert: 'backoffice/setAlert',
    }),
    async uploadFile(file) {
      this.isLoadingFile = true
      try {
        this.file = file
        let formData = new FormData()
        formData.append('file', file)
        const { id } = await this.postFile(formData)
        this.modifiedLayout.logo = {
          id,
        }
      } catch (err) {
        const errorContext = this.getErrorContext(err)

        this.setAlert({
          color: 'error',
          text: this.$t(
            `forms.individual.notifications.email.fileUpload.errors.${errorContext.name}`,
            errorContext.additionalInformation
          ),
        })
        this.file = null
      }
      this.isLoadingFile = false
    },
    async postFile(file) {
      await new Promise(resolve => setTimeout(resolve, 2000))
      return uploadFile(this.currentForm.clientId, file, this.currentForm.id, 'client')
    },
    deleteFile() {
      this.file = null
      this.modifiedLayout.logo = null
    },
    getErrorContext(error) {
      const isFileError = ['fileExtensions', 'fileSize'].includes(error.data?.rules)

      return {
        name: isFileError ? error.data?.rules : 'default',
        additionalInformation: isFileError
          ? { additionalInformation: error.data.expectedExtensions || error.data.expectedSize }
          : {},
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.forms-individual-notifications-email {
  @apply tw-px-4 tw-mx-auto tw-py-8;
  min-height: 100%;
  max-width: 768px;

  &__frame {
    @apply tw-w-full tw-p-4;

    &__content {
      @apply tw-w-full tw-h-full tw-shadow-inner;
      background-color: $light-grey;
    }
  }

  &__logo-url {
    @apply tw-whitespace-nowrap tw-overflow-hidden tw-block tw-text-sm;
    text-overflow: ellipsis;
  }
}
</style>
