<template>
  <UiActions large centered class="edit-actions">
    <v-btn :loading="isSaving" :disabled="isSaving" @click="cancelAction" outlined color="primary">
      <v-icon>{{ icons.mdiCancel }}</v-icon>
      <span>{{ $t('button.cancel') }}</span>
    </v-btn>
    <v-btn color="primary" type="submit" :loading="isSaving" :disabled="isSaving">
      <v-icon>{{ icons.mdiContentSaveCheckOutline }}</v-icon>
      <span>{{ $t('button.save') }}</span>
    </v-btn>
  </UiActions>
</template>

<script>
import UiActions from '@/components/UI/Actions.vue'
import { mdiContentSaveCheckOutline, mdiCancel } from '@mdi/js'

export default {
  name: 'PlatformFormsIndividualUIEditActions',
  components: {
    UiActions,
  },
  data: () => ({
    isSaving: false,
    icons: {
      mdiContentSaveCheckOutline,
      mdiCancel,
    },
  }),
  methods: {
    cancelAction() {
      this.$emit('cancelEdit')
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-actions {
  z-index: 10;
}
</style>
