<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div v-else-if="currentForm" class="forms-individual">
      <div>
        <UiBanner>
          <template v-slot:content>
            <div class="leads-list__content__platform-leads">
              <LeadsTopBar :formsList="formsList" :currentForm="currentForm" @changeForm="changeForm">
                <template v-slot:label>
                  <div
                    class="tw-flex tw-items-center tw-font-medium tw-text-xl tw-leading-6 tw-flex-grow-1 tw-mr-1"
                    v-dompurify-html="$t('forms.individual.title')"
                  ></div>
                </template>
              </LeadsTopBar>
            </div>
          </template>
        </UiBanner>

        <div>
          <v-tabs class="ui-forms" v-model="tab">
            <v-tab v-for="item in items" :key="item.tab" :href="'#' + item.value" @click="tabClick(item)"
              >{{ $t(`forms.individual.navigation.${item.value}`) }}
              <div class="ui-forms__modified-tab" v-if="modifiedTabs[item.value]"></div
            ></v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="tw-bg-transparent">
            <v-tab-item v-for="item in items" :key="item.tab" :value="item.value">
              <PlatformFormsIndividualSettings
                v-if="item.value === 'settings'"
                :form="currentForm"
                :updating="updating"
                :conditionableFields="conditionableFields"
                :locale="currentUser.locale"
                :key="latestUpdate"
                @updateSettings="updateSettings"
                @contentModified="showEditActions"
              />
              <PlatformFormsIndividualLocationSettings
                v-if="item.value === 'locationSettings' && currentForm.settings.located"
                :form="currentForm"
                :updating="updating"
                :key="latestUpdate"
                @updateLocationSettings="updateLocationSettings"
                @contentModified="showEditActions"
              />
              <PlatformFormsIndividualTranslations
                v-if="item.value === 'translations'"
                :translations="currentForm.translations"
                :dynamic-translations="dynamicTranslations"
                :default-translations="defaultTranslations"
                :mandatory-translations="mandatoryTranslations"
                :key="latestUpdate"
                :default-language="getShortLocal(currentClient.defaultLocaleIsoCode)"
                @updateTranslations="updateTranslations"
                @contentModified="showEditActions"
              />
              <PlatformFormsIndividualFields
                v-if="item.value === 'fields'"
                :fields="currentFormFields"
                :statics="currentFormStatics"
                :updating="updating"
                :key="latestUpdate"
                @update="updateFieldsAndStatics"
                @contentModified="showEditActions"
              />
              <PlatformFormsIndividualNotifications
                v-if="item.value === 'notifications'"
                :notifications="currentForm.notifications"
                :isLocatedForm="currentForm.settings.located"
                :isProductForm="currentForm.settings.product"
                :updating="updating"
                :key="latestUpdate"
                @updateNotifications="updateNotifications"
                @contentModified="showEditActions"
              />
              <PlatformFormsIndividualIntegration
                v-if="item.value === 'integration'"
                :form="currentForm"
                :updating="updating"
              />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </div>
  </v-fade-transition>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { reloadWidgets } from '@/utils/formWidget.util'
import { getShortLocal } from '@/utils/formatter.util'

import LeadsTopBar from '@/components/Leads/LeadsTopBar'
import PlatformFormsIndividualSettings from '@/components/Forms/Individual/Settings.vue'
import PlatformFormsIndividualLocationSettings from '@/components/Forms/Individual/LocationSettings.vue'
import PlatformFormsIndividualIntegration from '@/components/Forms/Individual/Integration.vue'
import PlatformFormsIndividualTranslations from '@/components/Forms/Individual/Translations.vue'
import PlatformFormsIndividualFields from '@/components/Forms/Individual/Fields.vue'
import PlatformFormsIndividualNotifications from '@/components/Forms/Individual/Notifications/Notifications.vue'
import UiBanner from '@/components/UI/Banner.vue'
import { isEmpty } from '@/utils/helper.util'

export default {
  name: 'PlatformForm',
  components: {
    UiBanner,
    LeadsTopBar,
    PlatformFormsIndividualSettings,
    PlatformFormsIndividualLocationSettings,
    PlatformFormsIndividualIntegration,
    PlatformFormsIndividualTranslations,
    PlatformFormsIndividualFields,
    PlatformFormsIndividualNotifications,
  },

  data: () => ({
    tab: 'settings',
    editMode: 0,
    latestUpdate: false,
    modifiedTabs: null,
    modifiedContent: {},

    hasEditedContent: false,
  }),

  async created() {
    this.setUpdating(true)
    const query = this.$route.query

    try {
      await Promise.all([this.getFormsList(), this.setFormDefaultTranslations()])
      isEmpty(query.formId)
        ? await this.setCurrentForm(this.formsList.rows[0].id)
        : await this.setCurrentForm(query.formId)
      this.$router.push({ query: { formId: this.currentForm.id } }).catch(err => err)
      await this.getDynamicTranslations(this.$route.query.formId)
    } catch {
      this.setAlert({
        color: 'error',
        text: this.$t('leads.notification.error.noForm'),
      })
    }
    this.modifiedTabs = this.items.reduce((acc, { value }) => {
      acc[value] = false
      return acc
    }, {})

    this.setUpdating(false)
  },

  watch: {
    currentForm: {
      async handler(newForm, oldForm) {
        if (newForm !== oldForm && this.currentForm) {
          this.$router.push({ query: { ...this.$route.query, formId: this.currentForm.id } }).catch(err => {
            return err
          })
        }
      },
      deep: true,
    },
  },

  computed: {
    ...mapState({
      updating: state => state.backoffice.updating,
      currentUser: state => state.backoffice.currentUser,
      currentForm: state => state.leads.currentForm,
      currentFormFields: state => state.leads.currentFormFields,
      currentFormStatics: state => state.leads.currentFormStatics,
      currentFormLeadsList: state => state.leads.currentFormLeadsList,
      formsList: state => state.leads.formsList,
      conditionableFields: state => state.leads.conditionableFields,
      currentClient: state => state.backoffice.currentClient,
      dynamicTranslations: state => state.leads.dynamicTranslations,
      defaultTranslations: state => state.leads.defaultTranslations,
      mandatoryTranslations: state => state.leads.mandatoryTranslations,
    }),

    computedFormName() {
      if (this.currentForm) {
        return this.currentForm.name
      }
      return null
    },

    items() {
      return [
        { tab: 0, value: 'settings' },
        ...(this.currentForm?.settings?.located ? [{ tab: 1, value: 'locationSettings' }] : []),
        { tab: 2, value: 'translations' },
        { tab: 3, value: 'fields' },
        { tab: 4, value: 'notifications' },
        { tab: 5, value: 'integration' },
      ]
    },
  },

  methods: {
    getShortLocal,
    ...mapActions({
      setUpdating: 'backoffice/setUpdating',
      getFormsList: 'leads/getFormsList',
      setCurrentForm: 'leads/setCurrentForm',
      updateCurrentFormSettings: 'leads/updateCurrentFormSettings',
      updateCurrentFormsFields: 'leads/updateCurrentFormsFields',
      updateCurrentFormsStatics: 'leads/updateCurrentFormsStatics',
      updateCurrentFormLocationSettings: 'leads/updateCurrentFormLocationSettings',
      updateCurrentFormTranslations: 'leads/updateCurrentFormTranslations',
      updateCurrentFormNotifications: 'leads/updateCurrentFormNotifications',
      getDynamicTranslations: 'leads/getDynamicTranslations',
      setFormDefaultTranslations: 'leads/setFormDefaultTranslations',
      setAlert: 'backoffice/setAlert',
    }),
    tabClick(item) {
      if (item.value === 'integration') {
        reloadWidgets()
      }
    },

    showEditActions(bool, tab, content) {
      this.hasEditedContent = bool
      this.modifiedTabs[tab] = bool
      this.modifiedContent = { ...this.modifiedContent, ...content }
    },

    async changeForm(item) {
      this.setUpdating(true)
      await this.setCurrentForm(item.id)
      this.setUpdating(false)
    },

    async updateFieldsAndStatics(fieldsAndStatics) {
      try {
        let needRefresh = false

        if (fieldsAndStatics.fields.length > 0) {
          needRefresh = true
          await this.updateCurrentFormsFields({ formId: this.$route.query.formId, fields: fieldsAndStatics.fields })
        }
        if (fieldsAndStatics.statics.length > 0) {
          needRefresh = true
          await this.updateCurrentFormsStatics({ formId: this.$route.query.formId, statics: fieldsAndStatics.statics })
        }

        if (needRefresh) await this.setCurrentForm(this.$route.query.formId)
        this.setAlert({
          color: 'success',
          text: this.$t('forms.individual.alert.success.fields'),
        })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.latestUpdate = Date.now()
    },
    async updateFields(fields) {
      try {
        await this.updateCurrentFormsFields({ formId: this.$route.query.formId, fields })
        await this.setCurrentForm(this.$route.query.formId)
        this.setAlert({
          color: 'success',
          text: this.$t('forms.individual.alert.success.fields'),
        })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.latestUpdate = Date.now()
    },
    async updateStatics(statics) {
      try {
        await this.updateCurrentFormsStatics({ formId: this.$route.query.formId, statics })
        await this.setCurrentForm(this.$route.query.formId)
        this.setAlert({
          color: 'success',
          text: this.$t('forms.individual.alert.success.fields'),
        })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.latestUpdate = Date.now()
    },
    async updateSettings(settings) {
      try {
        await this.updateCurrentFormSettings({ formId: this.$route.query.formId, settings })
        await this.setCurrentForm(this.$route.query.formId)
        this.setAlert({
          color: 'success',
          text: this.$t('forms.individual.alert.success.settings'),
        })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.latestUpdate = Date.now()
    },
    async updateLocationSettings(locationSettings) {
      try {
        await this.updateCurrentFormLocationSettings({ formId: this.$route.query.formId, locationSettings })
        this.setAlert({
          color: 'success',
          text: this.$t('forms.individual.alert.success.locationSettings'),
        })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.latestUpdate = Date.now()
    },
    async updateTranslations(translations) {
      try {
        await this.updateCurrentFormTranslations({
          formId: this.$route.query.formId,
          translations,
        })
        this.setAlert({
          color: 'success',
          text: this.$t('forms.individual.alert.success.translations'),
        })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.latestUpdate = Date.now()
    },
    async updateNotifications(notifications) {
      try {
        await this.updateCurrentFormNotifications({
          formId: this.$route.query.formId,
          notifications,
        })
        this.setAlert({
          color: 'success',
          text: this.$t('forms.individual.alert.success.notifications'),
        })
      } catch (e) {
        let errorMessage = this.$t('error.notification.default')
        if (e.data?.message && Array.isArray(e.data?.message)) {
          const errorList = e.data?.message.join('</br>').replace('\n', '</br>')
          errorMessage += `</br></br>${errorList}`
        }

        this.setAlert({
          color: 'error',
          text: errorMessage,
        })
      }
      this.latestUpdate = Date.now()
    },
  },
}
</script>
<style lang="scss">
.ui-forms {
  border-bottom: 1px solid map-deep-get($material-light, 'banner', 'border');

  &__modified-tab {
    position: absolute;
    top: 5px;
    right: 0;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: red;
  }
}
</style>
