import { getTimestampInSeconds } from '@/utils/date.util'

/**
 * Load Form Widget script tag
 * @param {String}  id  Widget script ID
 */
const SCRIPT_ID = 'GTRLeadWidgetScript'

export const formWidgetScriptTag = (version = 'latest') => {
  const script = document.createElement('script')

  script.id = SCRIPT_ID
  script.type = 'text/javascript'
  script.src = `https://lead-widget.gtrsuite.io/${version}/widget.min.umd.js`

  if (process.env.BO_ENV !== 'production') {
    script.src = `https://lead-gen-widget-preprod.s3.eu-west-3.amazonaws.com/latest/widget.min.umd.js`
  }
  return script
}

export function trackingScriptsTag(clientId, version = 'latest') {
  const mainScript = document.createElement('script')
  const optionsScript = document.createElement('script')

  mainScript.type = 'text/javascript'
  mainScript.src = `https://lead-widget.gtrsuite.io/${version}/tracker.min.umd.js`

  if (process.env.BO_ENV !== 'production') {
    mainScript.src = 'https://lead-gen-widget-preprod.s3.eu-west-3.amazonaws.com/latest/tracker.min.umd.js'
  }

  optionsScript.type = 'text/javascript'
  optionsScript.text = `window.gtr.intention.trackDisplay('${clientId}', { sku: <PRODUCT_SKU>, category: <PRODUCT_CATEGORY>, value: <PRODUCT_VALUE_IN_CENTS> });`

  return { mainScript, optionsScript }
}

export const loadScript = () => {
  if (isAlreadyLoaded()) {
    return
  }

  // Add timestamp param to reset browser cache
  const scriptTag = formWidgetScriptTag()
  scriptTag.src = `${scriptTag.src}?t=${getTimestampInSeconds()}`

  document.body.appendChild(scriptTag)
}

function isAlreadyLoaded() {
  return !!document.getElementById(SCRIPT_ID)
}

export const destroyScript = () => {
  window.gtr.lead.removeScript()
}

export const reloadWidgets = () => {
  if (isAlreadyLoaded()) window.gtr.lead.reloadWidgets()
}
