<template>
  <div :class="disabled ? '' : 'tw-cursor-pointer'" @click="emitToggle">
    <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
      <div v-if="largeTitle" class="tw-font-semibold tw-text-lg">
        {{ largeTitle }}
      </div>
      <div v-if="title" :class="[small ? 'tw-text-sm tw-text-gray-600' : 'tw-text-base', 'tw-font-medium']">
        {{ title }}
      </div>
      <v-switch
        class="tw-mt-0"
        v-model="editedValue"
        hide-details
        :disabled="disabled"
        :label="switchLabelByState(editedValue)"
        :dense="small"
        @change="emitToggle"
      />
    </div>
    <div v-if="subtitle" class="tw-text-xs tw-text-gray-500 tw-my-1">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiInputToggle',
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    largeTitle: {
      type: String,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    editedValue: false,
  }),
  methods: {
    switchLabelByState(state) {
      return state === true ? this.$t('label.toggle.on') : this.$t('label.toggle.off')
    },
    emitToggle() {
      if (this.disabled) return

      this.editedValue = !this.editedValue
      this.$emit('input', this.editedValue)
    },
  },
  created() {
    this.editedValue = this.value
  },
}
</script>
