<template>
  <div class="notification-edit">
    <v-icon class="tw-absolute tw--top-2 tw--right-2" small color="red" @click="deleteNotification()">{{
      icons.mdiCloseCircle
    }}</v-icon>
    <div class="md:tw-flex">
      <div class="tw-flex tw-flex-col tw-w-full" v-if="notificationEditing">
        <div class="md:tw-flex tw-flex-row tw-items-center tw-w-full">
          <div class="tw-flex-none tw-w-16 tw-mb-2 md:tw-mb-0">
            {{ $t(`forms.individual.notifications.internal.email.edit.label`) }}
          </div>
          <div class="md:tw-ml-4 md:tw-flex tw-flex-auto tw-w-full tw-items-center">
            <v-combobox
              v-model="modifiedRecipients"
              :filter="filterRepresentatives"
              :hide-no-data="!search"
              :disabled="!notificationEditing"
              :search-input.sync="search"
              :label="$t(`forms.individual.notifications.internal.email.placeholder`)"
              :items="locationRepresentatives"
              hide-selected
              hide-details
              multiple
              chips
              flat
              full-width
              outlined
              clearable
              class="forms-individual__notifications__location"
              @change="updateConditionStatus()"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <span>{{ $t(`forms.individual.notifications.internal.email.addMail`) }}&nbsp;</span>
                  <v-chip label small>
                    {{ search }}
                  </v-chip>
                </v-list-item>
              </template>
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                  v-if="item === Object(item)"
                  v-bind="attrs"
                  :input-value="selected"
                  label
                  small
                  :close="notificationEditing"
                  @click:close="parent.selectItem(item)"
                >
                  <span>
                    {{ getNotificationLabel(item.value) }}
                  </span>
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-chip dark label small>
                  {{ item.label }}
                </v-chip>
              </template>
            </v-combobox>

            <div class="md:tw-flex tw-items-center notification-edit__condition">
              <v-checkbox
                v-if="conditionableFields.length > 0"
                class="md:tw-ml-4"
                v-model="hasAddedCondition"
                :label="$t(`forms.individual.notifications.internal.email.edit.add.condition`)"
                :disabled="modifiedRecipients.length === 0"
                @change="addNotificationCondition($event)"
              ></v-checkbox>
            </div>
          </div>
        </div>
        <div v-if="hasAddedCondition" class="tw-mt-4 md:tw-flex tw-flex-row tw-items-center tw-flex-wrap">
          <div class="tw-flex-none tw-w-16 tw-mb-2 md:tw-mb-0">
            {{ $t(`forms.individual.notifications.internal.email.edit.field`) }}
          </div>
          <div class="tw-flex-1 md:tw-ml-4">
            <div class="md:tw-flex tw-flex-row tw-items-center tw-flex-wrap tw-mb-4">
              <v-select
                class="mb:tw-ml-4 tw-flex-1 tw-mb-2 md:tw-mb-0 md:tw-mr-4"
                v-model="modifiedCondition.field"
                :items="getConditionAvailableFields"
                :placeholder="$t(`forms.individual.notifications.internal.email.edit.placeholder.field`)"
                item-text="name"
                hide-details
                solo
                @change="updateConditionAvailableValues()"
              ></v-select>
              <v-select
                class="mb:tw-ml-4 tw-flex-1"
                v-model="modifiedCondition.operator"
                :items="computedOperators"
                :placeholder="$t(`forms.individual.notifications.internal.email.edit.placeholder.operator`)"
                item-text="name"
                hide-details
                solo
                @change="updateConditionStatus()"
              ></v-select>
            </div>
            <v-select
              class="tw-block mb:tw-ml-4"
              v-model="modifiedCondition.value"
              :items="conditionAvailableValues"
              :placeholder="$t(`forms.individual.notifications.internal.email.edit.placeholder.value`)"
              item-text="label"
              hide-details
              solo
              :multiple="isMultipleOperator"
              @change="updateConditionStatus()"
            ></v-select>
          </div>
        </div>
      </div>
      <div class="tw-flex tw-flex-col" v-else>
        <div
          v-dompurify-html="createNotificationContent().label"
          class="tw-font-semibold tw-text-sm tw-text-gray-600 tw-break-all"
        ></div>
        <div
          class="tw-text-xs tw-text-gray-600 tw-my-1 tw-break-all"
          v-dompurify-html="createNotificationContent().content"
        ></div>
      </div>
      <v-spacer />
      <div
        class="md:tw-flex tw-flex-row tw-items-center md:tw-ml-4 tw-text-center tw-mt-4 md:tw-mt-0"
        v-if="!notificationEditing"
      >
        <v-btn x-large icon color="info" @click="editNotification()">
          <v-icon large>{{ icons.mdiCircleEditOutline }}</v-icon>
        </v-btn>
      </div>
      <div class="md:tw-flex tw-flex-row tw-items-center md:tw-ml-4 tw-text-center tw-mt-4 md:tw-mt-0" v-else>
        <v-btn x-large icon color="info" @click="saveNotification()" :disabled="!canBeSavedNotifications">
          <v-icon x-large>{{ icons.mdiContentSaveCheckOutline }}</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiCloseCircle, mdiContentSaveCheckOutline, mdiCircleEditOutline } from '@mdi/js'
import { mapState } from 'vuex'
import { isEmpty } from '@/utils/helper.util'
import { countries } from '../../../../config/countries.config'

export default {
  name: 'UiNotificationEdit',

  props: {
    notification: {
      type: Object,
      required: true,
      default: null,
    },
    isLocatedForm: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    search: null,
    notificationEditing: false,
    hasAddedCondition: false,
    canBeSavedNotifications: false,
    conditionAvailableValues: [],
    modifiedRecipients: [],
    modifiedCondition: null,
    icons: {
      mdiCloseCircle,
      mdiContentSaveCheckOutline,
      mdiCircleEditOutline,
    },
  }),
  created() {
    this.modifiedRecipients = this.notification.recipients
    this.modifiedCondition = {
      field: this.notification.condition?.field || '',
      operator: this.notification.condition?.operator || '',
      value: this.notification.condition?.value || '',
    }

    this.hasAddedCondition = !this.hasEmptyCondition ? true : false
    this.notificationEditing = this.modifiedRecipients.length === 0

    if (this.hasAddedCondition) {
      const currentField = this.conditionableFields.find(field => field.name === this.modifiedCondition.field)
      this.conditionAvailableValues = currentField.type === 'country' ? this.getCountries() : currentField.options
    }
    this.updateConditionStatus()
  },

  watch: {
    modifiedRecipients(current, previous) {
      if (current.length === previous.length) return

      this.modifiedRecipients = current.reduce((cleanedRecpients, currentRecipient) => {
        let currentCleanedRecipient = currentRecipient

        if (typeof currentRecipient === 'string') {
          currentCleanedRecipient = {
            type: 'custom',
            value: currentRecipient,
          }
        }

        currentCleanedRecipient = { ...currentCleanedRecipient }
        delete currentCleanedRecipient.label

        // Remove empty recipients
        if (!isEmpty(currentCleanedRecipient.value.trim())) {
          cleanedRecpients.push(currentCleanedRecipient)
        }
        return cleanedRecpients
      }, [])
    },
  },

  computed: {
    ...mapState({
      conditionableFields: state => state.leads.conditionableFields,
    }),
    computedOperators() {
      return [
        {
          name: this.$t('forms.individual.notifications.internal.email.edit.operator.equals'),
          value: 'equals',
        },
        {
          name: this.$t('forms.individual.notifications.internal.email.edit.operator.notEquals'),
          value: 'not_equals',
        },
        {
          name: this.$t('forms.individual.notifications.internal.email.edit.operator.in'),
          value: 'in',
        },
      ]
    },
    locationRepresentatives() {
      if (this.isLocatedForm) {
        return [
          {
            type: 'location',
            label: this.$t(`forms.individual.notifications.internal.email.location.representative.store_owner`),
            value: 'store_owner',
          },
          {
            type: 'location',
            label: this.$t(`forms.individual.notifications.internal.email.location.representative.sales_rep`),
            value: 'sales_rep',
          },
          {
            type: 'location',
            label: this.$t(`forms.individual.notifications.internal.email.location.representative.brand_admin`),
            value: 'brand_admin',
          },
        ]
      }

      return []
    },
    getConditionAvailableFields() {
      return this.conditionableFields.map(field => field.name)
    },
    isMultipleOperator() {
      return this.modifiedCondition.operator === 'in'
    },
    hasEmptyCondition() {
      return (
        isEmpty(this.modifiedCondition) ||
        isEmpty(this.modifiedCondition.field) ||
        isEmpty(this.modifiedCondition.operator) ||
        isEmpty(this.modifiedCondition.value)
      )
    },
  },

  methods: {
    getCountries() {
      return countries[this.$i18n.locale].map(country => ({ label: country.name, value: country.code }))
    },
    filterRepresentatives(item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => (val != null ? val : '')

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
    },

    getNotificationLabel(slug) {
      const representativeNotification = this.locationRepresentatives.find(
        representative => representative.value === slug
      )

      if (representativeNotification) {
        return representativeNotification.label
      }
      return slug
    },

    updateConditionAvailableValues() {
      if (this.modifiedCondition.field !== '') {
        this.modifiedCondition.value = ''

        const currentField = this.conditionableFields.find(field => field.name === this.modifiedCondition.field)
        this.conditionAvailableValues = currentField.type === 'country' ? this.getCountries() : currentField.options
      }
      this.updateConditionStatus()
    },
    updateConditionStatus() {
      if (this.isMultipleOperator && typeof this.modifiedCondition?.value === 'string') {
        this.modifiedCondition.value = isEmpty(this.modifiedCondition?.value) ? [] : [this.modifiedCondition?.value]
      }
      if (!this.isMultipleOperator && Array.isArray(this.modifiedCondition?.value)) {
        this.modifiedCondition.value = this.modifiedCondition.value[0]
      }
      this.canBeSavedNotifications = this.modifiedRecipients.length > 0 && !this.hasEmptyConditionAdded()
    },
    hasEmptyConditionAdded() {
      return this.hasAddedCondition && this.hasEmptyCondition
    },

    createNotificationContent() {
      const recipients = this.modifiedRecipients
        .map(recipient => {
          if (recipient.type === 'location') {
            return this.getNotificationLabel(recipient.value)
          }
          return recipient.value
        })
        .join(', ')

      if (this.hasEmptyCondition) {
        return {
          label: this.$t(`forms.individual.notifications.internal.email.all.label`),
          content: this.$t(`forms.individual.notifications.internal.email.all.content`, {
            email: recipients,
          }),
        }
      }

      let notificationContent = {
        label: this.modifiedCondition.field,
      }

      return {
        ...notificationContent,
        content: this.$t(
          `forms.individual.notifications.internal.email.conditional.${this.modifiedCondition.operator}`,
          {
            email: recipients,
            field: this.modifiedCondition.field,
            value: this.modifiedCondition.value,
          }
        ),
      }
    },
    addNotificationCondition() {
      this.updateConditionStatus()
    },

    editNotification() {
      this.notificationEditing = true
      this.$emit('edit')
    },
    saveNotification() {
      this.notificationEditing = false

      const notificationToSave = {
        id: this.notification.id,
        recipients: this.modifiedRecipients,
      }
      if (!this.hasEmptyCondition) {
        notificationToSave.condition = this.modifiedCondition
      }
      this.$emit('save', notificationToSave)
    },
    deleteNotification() {
      this.$emit('delete')
    },
  },
}
</script>

<style lang="scss">
.notification-edit {
  &__condition {
    .v-input--is-disabled .v-input__slot {
      background: none !important;
    }
  }
}
</style>
