<template>
  <div>
    <FormsIndividualNotificationsSideBar :parent-refs="$refs" v-if="$vuetify.breakpoint.mdAndUp" />
    <form
      class="tw-flex tw-flex-col tw-gap-6 tw-pr-0 md:tw-pr-60"
      @submit.prevent="onSubmit"
      novalidate
      autocomplete="off"
      :key="sectionKey"
    >
      <div>
        <div class="tw-mb-8" ref="sender">
          <UiCardForm class="forms-individual-notifications">
            <div class="tw-flex tw-flex-col">
              <span class="tw-font-semibold tw-text-lg">{{ $t('forms.individual.notifications.sender.title') }}</span>
            </div>
            <div class="tw-text-xs tw-text-gray-500 tw-my-1">
              {{ $t('forms.individual.notifications.sender.description') }}
            </div>

            <div class="forms-individual-notifications__sender">
              <div class="tw-mt-4 tw-grid sm:tw-grid-cols-2">
                <UiInputTextField
                  class="tw-mr-0 sm:tw-mr-4"
                  v-model="modifiedNotifications.sender.name"
                  :label="$t('forms.individual.notifications.sender.name.title')"
                  :placeholder="$t('forms.individual.notifications.sender.name.placeholder')"
                />
                <UiInputTextField
                  class="tw-ml-0 sm:tw-ml-4"
                  v-model="modifiedNotifications.sender.email"
                  :label="$t('forms.individual.notifications.sender.email.title')"
                  :placeholder="$t('forms.individual.notifications.sender.email.placeholder')"
                  :suffix="gtrSuffix"
                  :error-messages="invalidEmailError"
                />
              </div>
            </div>
          </UiCardForm>
        </div>

        <!-- Consumer Notification -->
        <div class="tw-mb-8" ref="consumer">
          <UiCardForm class="forms-individual-notifications">
            <div class="tw-flex tw-flex-col">
              <span class="tw-font-semibold tw-text-lg">{{ $t('forms.individual.notifications.consumer.title') }}</span>
            </div>

            <div v-if="isLocatedForm">
              <div class="tw-flex tw-flex-col tw-mt-4">
                <span class="tw-font-medium tw-text-sm tw-text-gray-600">{{
                  $t('forms.individual.notifications.internal.configuration.information.title')
                }}</span>
                <span class="tw-text-xs tw-text-gray-500 tw-my-1">{{
                  $t('forms.individual.notifications.internal.configuration.information.description')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-gap-4 tw-pt-2">
                <UiInputToggle
                  v-model="modifiedNotifications.consumer.display.location"
                  :title="$t(`forms.individual.notifications.internal.configuration.information.location.title`)"
                  :subtitle="$t(`forms.individual.notifications.internal.configuration.information.location.subtitle`)"
                  small
                />
                <UiInputToggle
                  v-model="modifiedNotifications.consumer.display.company"
                  :title="$t(`forms.individual.notifications.internal.configuration.information.company.title`)"
                  :subtitle="$t(`forms.individual.notifications.internal.configuration.information.company.subtitle`)"
                  small
                />
              </div>
            </div>

            <div ref="confirmation" class="forms-individual-notifications__confirmation">
              <div class="tw-font-medium tw-text-base tw-pt-6">
                {{ $t('forms.individual.notifications.consumer.newLead.title') }}
              </div>
              <div class="tw-text-xs tw-text-gray-500 tw-my-1">
                {{ $t('forms.individual.notifications.consumer.newLead.description') }}
              </div>
              <div class="tw-font-medium tw-text-sm tw-text-gray-600 tw-mt-6">
                {{ $t('forms.individual.notifications.common.email.title') }}
              </div>

              <div class="tw-mt-4 tw-mb-6 tw-grid sm:tw-grid-cols-2 tw-gap-8">
                <FormsIndividualLocalizedInput
                  :defaultContent="getTranslatableSet('consumer', 'newLead', 'subject')"
                  :placeholder="$t('forms.individual.notifications.common.email.subject.placeholder')"
                  :title="$t('forms.individual.notifications.common.email.subject.title')"
                  @input="editContent($event, 'consumer', 'newLead', 'subject')"
                />

                <FormsIndividualLocalizedInput
                  :defaultContent="getTranslatableSet('consumer', 'newLead', 'title')"
                  :title="$t('forms.individual.notifications.common.email.heading.title')"
                  :placeholder="$t('forms.individual.notifications.common.email.heading.placeholder')"
                  @input="editContent($event, 'consumer', 'newLead', 'title')"
                />
              </div>
            </div>

            <v-divider class="tw-mt-6" />

            <div ref="acceptance" class="forms-individual-notifications__acceptance">
              <div class="tw-font-medium tw-text-base tw-pt-10">
                {{ $t('forms.individual.notifications.consumer.acceptance.title') }}
              </div>
              <div class="tw-text-xs tw-text-gray-500 tw-my-1">
                {{ $t('forms.individual.notifications.consumer.acceptance.description') }}
              </div>
              <div class="tw-font-medium tw-text-sm tw-text-gray-600 tw-mt-6">
                {{ $t('forms.individual.notifications.common.email.title') }}
              </div>

              <div class="tw-mt-4 tw-grid sm:tw-grid-cols-2 tw-gap-8">
                <FormsIndividualLocalizedInput
                  :defaultContent="getTranslatableSet('consumer', 'acceptance', 'subject')"
                  :title="$t('forms.individual.notifications.common.email.subject.title')"
                  :placeholder="$t('forms.individual.notifications.common.email.subject.placeholder')"
                  @input="editContent($event, 'consumer', 'acceptance', 'subject')"
                />
                <FormsIndividualLocalizedInput
                  :defaultContent="getTranslatableSet('consumer', 'acceptance', 'title')"
                  :title="$t('forms.individual.notifications.common.email.heading.title')"
                  :placeholder="$t('forms.individual.notifications.common.email.heading.placeholder')"
                  @input="editContent($event, 'consumer', 'acceptance', 'title')"
                />
              </div>

              <div class="tw-mt-4 tw-mb-4 tw-grid sm:tw-grid-cols-1">
                <FormsIndividualLocalizedInput
                  :defaultContent="getTranslatableSet('consumer', 'acceptance', 'content')"
                  :title="$t('forms.individual.notifications.consumer.acceptance.content.title')"
                  :placeholder="$t('forms.individual.notifications.consumer.acceptance.content.placeholder')"
                  @input="editContent($event, 'consumer', 'acceptance', 'content')"
                />
              </div>
            </div>

            <v-divider class="tw-mt-6" />

            <div>
              <div ref="consumerFollowUp">
                <UiInputToggle
                  class="tw-pt-10"
                  v-model="modifiedNotifications.consumer.followUp.active"
                  :title="$t(`forms.individual.notifications.consumer.followUp.title`)"
                  :subtitle="$t('forms.individual.notifications.consumer.followUp.description')"
                />
              </div>

              <template v-if="displayConsumerFollowUpSection">
                <div class="tw-font-medium tw-text-sm tw-text-gray-600 tw-mt-6">
                  {{ $t('forms.individual.notifications.consumer.followUp.delay.title') }}
                </div>

                <div class="tw-mt-4 tw-mb-4 tw-grid sm:tw-grid-cols-1">
                  <UiInputTextField
                    v-model.number="modifiedNotifications.consumer.followUp.delay"
                    type="number"
                    :label="$t('forms.individual.notifications.consumer.followUp.delay.subtitle')"
                    :placeholder="$t('forms.individual.notifications.consumer.followUp.delay.placeholder')"
                  />
                </div>

                <div>
                  <div class="tw-font-medium tw-text-sm tw-text-gray-600 tw-mt-6">
                    {{ $t('forms.individual.notifications.common.email.title') }}
                  </div>

                  <div class="tw-mt-4 tw-grid sm:tw-grid-cols-2 tw-gap-8">
                    <FormsIndividualLocalizedInput
                      :defaultContent="getTranslatableSet('consumer', 'followUp', 'subject')"
                      :title="$t('forms.individual.notifications.common.email.subject.title')"
                      :placeholder="$t('forms.individual.notifications.common.email.subject.placeholder')"
                      @input="editContent($event, 'consumer', 'followUp', 'subject')"
                    />
                    <FormsIndividualLocalizedInput
                      :defaultContent="getTranslatableSet('consumer', 'followUp', 'title')"
                      :title="$t('forms.individual.notifications.common.email.heading.title')"
                      :placeholder="$t('forms.individual.notifications.common.email.heading.placeholder')"
                      @input="editContent($event, 'consumer', 'followUp', 'title')"
                    />
                  </div>
                </div>
              </template>
            </div>

            <v-divider class="tw-mt-6" />

            <div ref="consumerLayout" class="forms-individual-notifications__layout">
              <div class="tw-font-medium tw-text-base tw-pt-10">
                {{ $t('forms.individual.notifications.consumer.layout.title') }}
              </div>
              <span class="tw-text-xs tw-text-gray-500">
                <a target="_blank" :href="getMjmlDocumentationLink" @click.stop>{{
                  $t('forms.individual.notifications.consumer.layout.documentation.link')
                }}</a>
                {{ $t('forms.individual.notifications.consumer.layout.documentation.description') }}
              </span>

              <div class="tw-mt-2 tw-mb-4 tw-grid sm:tw-grid-cols-1">
                <FormsIndividualLocalizedInput
                  :defaultContent="getConsumerFiles"
                  hasFile
                  @change="uploadConsumerTemplate"
                  @deleteFile="deleteConsumerTemplate"
                />
              </div>
            </div>
          </UiCardForm>
        </div>

        <div class="tw-mb-8" ref="administrators">
          <UiCardForm class="forms-individual-notifications">
            <div>
              <span class="tw-font-semibold tw-text-lg">{{ $t('forms.individual.notifications.internal.title') }}</span>
              <div class="tw-text-xs tw-text-gray-500 tw-my-1">
                {{ $t('forms.individual.notifications.internal.description') }}
              </div>

              <v-divider class="tw-mt-6" />

              <!-- Configuration -->
              <div ref="internalConfiguration" class="tw-flex tw-flex-col tw-pt-10">
                <span class="tw-font-medium tw-text-base">{{
                  $t('forms.individual.notifications.internal.configuration.title')
                }}</span>
              </div>

              <!-- Notifications Locale -->
              <div class="tw-font-medium tw-text-sm tw-text-gray-600 tw-mt-6">
                {{ $t('forms.individual.notifications.internal.configuration.locale.label') }}
              </div>
              <v-select
                class="tw-font-normal tw-text-sm tw-mt-4"
                v-model="modifiedNotifications.internal.locale"
                :items="notificationLocales"
                item-text="label"
                item-value="value"
                outlined
                dense
                clearable
                hide-details="auto"
              />

              <!-- Internal informations -->
              <div class="tw-flex tw-flex-col tw-mt-4">
                <span class="tw-font-medium tw-text-sm tw-text-gray-600">{{
                  $t('forms.individual.notifications.internal.configuration.information.title')
                }}</span>
                <span class="tw-text-xs tw-text-gray-500 tw-my-1">{{
                  $t('forms.individual.notifications.internal.configuration.information.description')
                }}</span>
              </div>

              <div class="tw-grid tw-grid-cols-2 tw-gap-4 tw-pt-2">
                <UiInputToggle
                  v-model="modifiedNotifications.internal.display.tag"
                  :title="$t(`forms.individual.notifications.internal.configuration.information.tag.title`)"
                  :subtitle="$t(`forms.individual.notifications.internal.configuration.information.tag.subtitle`)"
                  small
                />
                <UiInputToggle
                  v-if="isProductForm"
                  v-model="modifiedNotifications.internal.display.sku"
                  :title="$t(`forms.individual.notifications.internal.configuration.information.sku.title`)"
                  :subtitle="$t(`forms.individual.notifications.internal.configuration.information.sku.subtitle`)"
                  small
                />
                <UiInputToggle
                  v-if="isLocatedForm"
                  v-model="modifiedNotifications.internal.display.location"
                  :title="$t(`forms.individual.notifications.internal.configuration.information.location.title`)"
                  :subtitle="$t(`forms.individual.notifications.internal.configuration.information.location.subtitle`)"
                  small
                />
                <UiInputToggle
                  v-if="isLocatedForm"
                  v-model="modifiedNotifications.internal.display.company"
                  :title="$t(`forms.individual.notifications.internal.configuration.information.company.title`)"
                  :subtitle="$t(`forms.individual.notifications.internal.configuration.information.company.subtitle`)"
                  small
                />
              </div>

              <v-divider class="tw-mt-6" />

              <!-- New Lead Subscriber -->
              <div ref="newLeadSubscriber" class="tw-flex tw-flex-col tw-pt-10">
                <span class="tw-font-medium tw-text-base">{{
                  $t('forms.individual.notifications.internal.newLeadSubscriber.title')
                }}</span>
                <span class="tw-text-xs tw-text-gray-500 tw-my-1">{{
                  $t('forms.individual.notifications.internal.newLeadSubscriber.description')
                }}</span>
              </div>
              <div class="tw-font-medium tw-text-sm tw-text-gray-600 tw-mt-6">
                {{ $t('forms.individual.notifications.common.email.title') }}
              </div>
              <div class="tw-mt-4 tw-grid sm:tw-grid-cols-2 tw-gap-8">
                <FormsIndividualLocalizedInput
                  :defaultContent="getTranslatableSet('internal', 'newLeadSubscriber', 'subject')"
                  :title="$t('forms.individual.notifications.common.email.subject.title')"
                  :placeholder="$t('forms.individual.notifications.common.email.subject.placeholder')"
                  @input="editContent($event, 'internal', 'newLeadSubscriber', 'subject')"
                />
                <FormsIndividualLocalizedInput
                  :defaultContent="getTranslatableSet('internal', 'newLeadSubscriber', 'title')"
                  :title="$t('forms.individual.notifications.common.email.heading.title')"
                  :placeholder="$t('forms.individual.notifications.common.email.heading.placeholder')"
                  @input="editContent($event, 'internal', 'newLeadSubscriber', 'title')"
                />
              </div>

              <!-- New Lead Subscriber Notifications -->
              <div class="tw-flex tw-flex-col tw-mt-4">
                <span class="tw-font-medium tw-text-sm tw-text-gray-600">{{
                  $t('forms.individual.notifications.internal.email.title')
                }}</span>
                <span class="tw-text-xs tw-text-gray-500 tw-my-1">{{
                  $t('forms.individual.notifications.internal.email.description')
                }}</span>
              </div>
              <UiNotificationList
                v-model="modifiedNotifications.internal.newLeadSubscriber.emails"
                :isLocatedForm="isLocatedForm"
              />
              <v-divider class="tw-mt-6" />

              <!-- New Lead Observer -->
              <div ref="newLeadObserver" class="tw-flex tw-flex-col tw-pt-10">
                <span class="tw-font-medium tw-text-base">{{
                  $t('forms.individual.notifications.internal.newLeadObserver.title')
                }}</span>
                <span class="tw-text-xs tw-text-gray-500 tw-my-1">{{
                  $t('forms.individual.notifications.internal.newLeadObserver.description')
                }}</span>
              </div>
              <div class="tw-font-medium tw-text-sm tw-text-gray-600 tw-mt-6">
                {{ $t('forms.individual.notifications.common.email.title') }}
              </div>
              <div class="tw-mt-4 tw-grid sm:tw-grid-cols-2 tw-gap-8">
                <FormsIndividualLocalizedInput
                  :defaultContent="getTranslatableSet('internal', 'newLeadObserver', 'subject')"
                  :title="$t('forms.individual.notifications.common.email.subject.title')"
                  :placeholder="$t('forms.individual.notifications.common.email.subject.placeholder')"
                  @input="editContent($event, 'internal', 'newLeadObserver', 'subject')"
                />
                <FormsIndividualLocalizedInput
                  :defaultContent="getTranslatableSet('internal', 'newLeadObserver', 'title')"
                  :title="$t('forms.individual.notifications.common.email.heading.title')"
                  :placeholder="$t('forms.individual.notifications.common.email.heading.placeholder')"
                  @input="editContent($event, 'internal', 'newLeadObserver', 'title')"
                />
              </div>

              <!-- New Lead Observer Notifications -->
              <div class="tw-flex tw-flex-col tw-mt-4">
                <span class="tw-font-medium tw-text-sm tw-text-gray-600">{{
                  $t('forms.individual.notifications.internal.email.title')
                }}</span>
                <span class="tw-text-xs tw-text-gray-500 tw-my-1">{{
                  $t('forms.individual.notifications.internal.email.description')
                }}</span>
              </div>
              <UiNotificationList
                v-model="modifiedNotifications.internal.newLeadObserver.emails"
                :isLocatedForm="isLocatedForm"
              />

              <v-divider class="tw-mt-6" />

              <!-- Internal Reject -->
              <div>
                <div ref="internalReject">
                  <UiInputToggle
                    class="tw-pt-10"
                    v-model="modifiedNotifications.internal.reject.active"
                    :title="$t(`forms.individual.notifications.internal.reject.title`)"
                    :subtitle="$t('forms.individual.notifications.internal.reject.description')"
                  />
                </div>

                <template v-if="displayInternalRejectSection">
                  <div>
                    <div class="tw-font-medium tw-text-sm tw-text-gray-600 tw-mt-6">
                      {{ $t('forms.individual.notifications.common.email.title') }}
                    </div>

                    <div class="tw-mt-4 tw-grid sm:tw-grid-cols-2 tw-gap-8">
                      <FormsIndividualLocalizedInput
                        :defaultContent="getTranslatableSet('internal', 'reject', 'subject')"
                        :title="$t('forms.individual.notifications.common.email.subject.title')"
                        :placeholder="$t('forms.individual.notifications.common.email.subject.placeholder')"
                        @input="editContent($event, 'internal', 'reject', 'subject')"
                      />
                      <FormsIndividualLocalizedInput
                        :defaultContent="getTranslatableSet('internal', 'reject', 'title')"
                        :title="$t('forms.individual.notifications.common.email.heading.title')"
                        :placeholder="$t('forms.individual.notifications.common.email.heading.placeholder')"
                        @input="editContent($event, 'internal', 'reject', 'title')"
                      />
                    </div>
                  </div>
                </template>
              </div>

              <v-divider class="tw-mt-6" />

              <!-- Webhook notifications -->
              <div class="tw-flex tw-flex-col tw-pt-6">
                <span class="tw-font-medium tw-text-sm tw-text-gray-600">{{
                  $t('forms.individual.notifications.internal.webhook.title')
                }}</span>
                <span class="tw-text-xs tw-text-gray-500 tw-mt-1">{{
                  $t('forms.individual.notifications.internal.webhook.description')
                }}</span>
                <span class="tw-text-xs tw-text-gray-500">
                  {{ $t('forms.individual.notifications.internal.webhook.documentation.description') }}
                  <a target="_blank" :href="getWebhookDocumentationLink" @click.stop>{{
                    $t('forms.individual.notifications.internal.webhook.documentation.link')
                  }}</a>
                </span>
              </div>
              <UiInputTextField
                class="tw-mt-4"
                v-model.trim="modifiedNotifications.internal.webhooks.newLead"
                :error-messages="newLeadWebhookErrors"
                :placeholder="$t('forms.individual.notifications.internal.webhook.placeholder')"
              />
            </div>
            <v-divider class="tw-mt-6" />

            <!-- Forwarded Notification -->
            <div ref="forwarded" class="tw-flex tw-flex-col tw-pt-10">
              <span class="tw-font-medium tw-text-base">{{
                $t('forms.individual.notifications.forwarded.title')
              }}</span>
              <span class="tw-text-xs tw-text-gray-500 tw-mt-1">{{
                $t('forms.individual.notifications.forwarded.description')
              }}</span>
            </div>
            <div class="tw-font-medium tw-text-sm tw-text-gray-600 tw-mt-6">
              {{ $t('forms.individual.notifications.common.email.title') }}
            </div>
            <div class="tw-mt-4 tw-grid sm:tw-grid-cols-2 tw-gap-8">
              <div class="tw-text-xs tw-text-gray-500 tw-flex tw-flex-col tw-mr-0">
                <FormsIndividualLocalizedInput
                  :defaultContent="getTranslatableSet('internal', 'forwardedLead', 'subject')"
                  id="forwardedLeadSubject"
                  :title="$t('forms.individual.notifications.common.email.subject.title')"
                  :placeholder="$t('forms.individual.notifications.common.email.subject.placeholder')"
                  @input="editContent($event, 'internal', 'forwardedLead', 'subject')"
                />
              </div>
              <div class="tw-text-xs tw-text-gray-500 tw-flex tw-flex-col w-ml-0">
                <FormsIndividualLocalizedInput
                  :defaultContent="getTranslatableSet('internal', 'forwardedLead', 'title')"
                  id="forwardedLeadTitle"
                  :placeholder="$t('forms.individual.notifications.common.email.heading.placeholder')"
                  :title="$t('forms.individual.notifications.common.email.heading.title')"
                  @input="editContent($event, 'internal', 'forwardedLead', 'title')"
                />
              </div>
            </div>
            <v-divider class="tw-mt-6" />

            <div>
              <!-- FollowUp Notification -->
              <div ref="internalFollowUp">
                <UiInputToggle
                  class="tw-pt-10"
                  v-model="modifiedNotifications.internal.followUp.active"
                  :title="$t(`forms.individual.notifications.internal.followUp.title`)"
                  :subtitle="$t(`forms.individual.notifications.internal.followUp.frequency.sublabel`)"
                />
              </div>

              <template v-if="displayInternalFollowUpSection">
                <div class="tw-font-medium tw-text-sm tw-text-gray-600 tw-mt-6">
                  {{ $t('forms.individual.notifications.internal.followUp.frequency.label') }}
                </div>

                <div>
                  <div class="tw-flex tw-flex-col tw-mr-0 sm:tw-mr-0">
                    <div class="tw-flex tw-justify-center">
                      <v-radio-group v-model="modifiedNotifications.internal.followUp.frequency" row>
                        <v-radio
                          class="tw-font-medium tw-mr-18 tw-flex-col-reverse tw-gap-2"
                          :label="$t('forms.individual.notifications.internal.followUp.frequency.daily')"
                          value="daily"
                        />
                        <v-radio
                          class="tw-font-medium tw-ml-16 tw-flex-col-reverse tw-gap-2"
                          :label="$t('forms.individual.notifications.internal.followUp.frequency.weekly')"
                          value="weekly"
                        />
                      </v-radio-group>
                    </div>
                  </div>
                </div>

                <div class="tw-font-medium tw-text-sm tw-text-gray-600 tw-mt-2">
                  {{ $t('forms.individual.notifications.common.email.title') }}
                </div>
                <div class="tw-mt-4 tw-grid sm:tw-grid-cols-2 tw-gap-8">
                  <FormsIndividualLocalizedInput
                    :defaultContent="getTranslatableSet('internal', 'followUp', 'subject')"
                    :title="$t('forms.individual.notifications.common.email.subject.title')"
                    :placeholder="$t('forms.individual.notifications.common.email.subject.placeholder')"
                    @input="editContent($event, 'internal', 'followUp', 'subject')"
                  />
                </div>
                <div class="tw-grid sm:tw-grid-cols-2 tw-mt-4 tw-gap-8">
                  <FormsIndividualLocalizedInput
                    :defaultContent="getTranslatableSet('internal', 'followUp', 'acceptedTitle')"
                    :title="$t('forms.individual.notifications.internal.followUp.email.acceptedTitle.title')"
                    :placeholder="
                      $t('forms.individual.notifications.internal.followUp.email.acceptedTitle.placeholder')
                    "
                    @input="editContent($event, 'internal', 'followUp', 'acceptedTitle')"
                  />
                  <FormsIndividualLocalizedInput
                    :defaultContent="getTranslatableSet('internal', 'followUp', 'unacceptedTitle')"
                    :title="$t('forms.individual.notifications.internal.followUp.email.unacceptedTitle.title')"
                    :placeholder="
                      $t('forms.individual.notifications.internal.followUp.email.unacceptedTitle.placeholder')
                    "
                    @input="editContent($event, 'internal', 'followUp', 'unacceptedTitle')"
                  />
                </div>
              </template>
            </div>

            <div>
              <!-- followUpObserver Notification -->
              <div ref="internalFollowUpObserver">
                <UiInputToggle
                  class="tw-pt-10"
                  v-model="modifiedNotifications.internal.followUpObserver.active"
                  :title="$t(`forms.individual.notifications.internal.followUpObserver.title`)"
                  :subtitle="$t(`forms.individual.notifications.internal.followUpObserver.frequency.sublabel`)"
                />
              </div>

              <template v-if="displayInternalFollowUpObserverSection">
                <div class="tw-font-medium tw-text-sm tw-text-gray-600 tw-mt-6">
                  {{ $t('forms.individual.notifications.internal.followUpObserver.frequency.label') }}
                </div>

                <div>
                  <div class="tw-flex tw-flex-col tw-mr-0 sm:tw-mr-0">
                    <div class="tw-flex tw-justify-center">
                      <v-radio-group v-model="modifiedNotifications.internal.followUpObserver.frequency" row>
                        <v-radio
                          class="tw-font-medium tw-mr-18 tw-flex-col-reverse tw-gap-2"
                          :label="$t('forms.individual.notifications.internal.followUpObserver.frequency.daily')"
                          value="daily"
                        />
                        <v-radio
                          class="tw-font-medium tw-ml-16 tw-flex-col-reverse tw-gap-2"
                          :label="$t('forms.individual.notifications.internal.followUpObserver.frequency.weekly')"
                          value="weekly"
                        />
                      </v-radio-group>
                    </div>
                  </div>
                </div>

                <div class="tw-font-medium tw-text-sm tw-text-gray-600 tw-mt-2">
                  {{ $t('forms.individual.notifications.common.email.title') }}
                </div>
                <div class="tw-mt-4 tw-grid sm:tw-grid-cols-2 tw-gap-8">
                  <FormsIndividualLocalizedInput
                    :defaultContent="getTranslatableSet('internal', 'followUpObserver', 'subject')"
                    :title="$t('forms.individual.notifications.common.email.subject.title')"
                    :placeholder="$t('forms.individual.notifications.common.email.subject.placeholder')"
                    @input="editContent($event, 'internal', 'followUpObserver', 'subject')"
                  />
                </div>
                <div class="tw-grid sm:tw-grid-cols-2 tw-mt-4 tw-gap-8">
                  <FormsIndividualLocalizedInput
                    :defaultContent="getTranslatableSet('internal', 'followUpObserver', 'acceptedTitle')"
                    :title="$t('forms.individual.notifications.internal.followUpObserver.email.acceptedTitle.title')"
                    :placeholder="
                      $t('forms.individual.notifications.internal.followUpObserver.email.acceptedTitle.placeholder')
                    "
                    @input="editContent($event, 'internal', 'followUpObserver', 'acceptedTitle')"
                  />
                  <FormsIndividualLocalizedInput
                    :defaultContent="getTranslatableSet('internal', 'followUpObserver', 'unacceptedTitle')"
                    :title="$t('forms.individual.notifications.internal.followUpObserver.email.unacceptedTitle.title')"
                    :placeholder="
                      $t('forms.individual.notifications.internal.followUpObserver.email.unacceptedTitle.placeholder')
                    "
                    @input="editContent($event, 'internal', 'followUpObserver', 'unacceptedTitle')"
                  />
                </div>
              </template>
            </div>
          </UiCardForm>
        </div>

        <div class="tw-mb-8" ref="emailCustomization">
          <forms-individual-notifications-email-customization
            :layout="notifications.layout"
            @updateLayout="modifiedNotifications.layout = $event"
          />
        </div>
      </div>
      <PlatformFormsIndividualUIEditActions v-if="hasEditedContent" type="submit" @cancelEdit="cancelEdit" />
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import FormsIndividualNotificationsSideBar from '@/components/Forms/Individual/Notifications/SideBar.vue'
import PlatformFormsIndividualUIEditActions from '@/components/Forms/Individual/UI/EditActions.vue'
import UiInputTextField from '@/components/UI/input/InputTextField.vue'
import UiCardForm from '@/components/UI/CardForm.vue'
import UiInputToggle from '@/components/UI/input/InputToggle.vue'
import FormsIndividualLocalizedInput from '@/components/Forms/Individual/LocalizedInput.vue'
import UiNotificationList from '@/components/Forms/Individual/UI/NotificationList.vue'
import FormsIndividualNotificationsEmailCustomization from '@/components/Forms/Individual/Notifications/EmailCustomization.vue'
import { clone } from '@/utils/utilities.util'

import { mdiContentSaveCheckOutline } from '@mdi/js'
import { url, email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

export default {
  name: 'FormsIndividualNotificationsConfigurations',
  components: {
    UiNotificationList,
    PlatformFormsIndividualUIEditActions,
    UiInputTextField,
    UiInputToggle,
    FormsIndividualLocalizedInput,
    UiCardForm,
    FormsIndividualNotificationsEmailCustomization,
    FormsIndividualNotificationsSideBar,
  },
  mixins: [validationMixin],
  props: {
    isLocatedForm: {
      type: Boolean,
      required: true,
    },
    isProductForm: {
      type: Boolean,
      required: true,
    },
    notifications: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  data() {
    return {
      gtrSuffix: '@gtrsuite.com',
      sectionKey: 0,
      file: null,
      positionTop: 0,
      icons: {
        mdiContentSaveCheckOutline,
      },
      modifiedNotifications: null,
      initialNotifications: null,
      windowWidth: window.innerWidth,
      translatedContent: null,
    }
  },
  watch: {
    modifiedNotifications: {
      handler(newSettings) {
        this.cleanSettings(newSettings)
        this.$emit('contentModified', this.hasEditedContent, 'notifications')
      },
      deep: true,
    },
  },
  created() {
    this.modifiedNotifications = JSON.parse(JSON.stringify(this.notifications))
    //need clone because spread doesn't work for whatever reason
    this.initialNotifications = clone(this.modifiedNotifications)
  },
  computed: {
    ...mapState({
      currentForm: state => state.leads.currentForm,
    }),
    getLocales() {
      return this.currentForm.translations.activatedLocales
    },
    getConsumerFiles() {
      return this.currentForm.translations.activatedLocales.reduce((fileSet, locale) => {
        fileSet[locale] = null

        if (this.modifiedNotifications.consumer?.template && this.modifiedNotifications.consumer.template[locale]) {
          fileSet[locale] = new File([this.modifiedNotifications.consumer.template[locale]], 'layout.mjml', {
            type: 'text/html',
          })
        }
        return fileSet
      }, {})
    },
    getWebhookDocumentationLink() {
      if (this.$i18n.locale == 'fr') {
        return 'https://gtrsuite.notion.site/Integrations-LGE-b78e25e1a2104f0eb24c61b9bbef7da3'
      }
      return 'https://gtrsuite.notion.site/LGE-Integrations-b646ca1345a14c9fbe30ffaac7ee8a13'
    },
    getMjmlDocumentationLink() {
      return 'https://mjml.io/try-it-live/SS36ug6_T-'
    },
    notificationLocales() {
      return [
        {
          label: this.$t('forms.individual.notifications.internal.configuration.locale.en'),
          value: 'en',
        },
        {
          label: this.$t('forms.individual.notifications.internal.configuration.locale.fr'),
          value: 'fr',
        },
        {
          label: this.$t('forms.individual.notifications.internal.configuration.locale.es'),
          value: 'es',
        },
        {
          label: this.$t('forms.individual.notifications.internal.configuration.locale.it'),
          value: 'it',
        },
        {
          label: this.$t('forms.individual.notifications.internal.configuration.locale.de'),
          value: 'de',
        },
        {
          label: this.$t('forms.individual.notifications.internal.configuration.locale.no'),
          value: 'no',
        },
      ]
    },
    displayInternalFollowUpSection() {
      return this.modifiedNotifications.internal.followUp.active
    },
    displayInternalFollowUpObserverSection() {
      return this.modifiedNotifications.internal.followUpObserver.active
    },
    displayConsumerFollowUpSection() {
      return this.modifiedNotifications.consumer.followUp.active
    },
    displayInternalRejectSection() {
      return this.modifiedNotifications.internal.reject.active
    },
    newLeadWebhookErrors() {
      const errors = []
      if (!this.$v.modifiedNotifications.internal.webhooks.newLead.$dirty) return errors
      !this.$v.modifiedNotifications.internal.webhooks.newLead.url && errors.push(this.$t('error.url'))
      return errors
    },
    invalidEmailError() {
      const errors = []
      if (!this.$v.modifiedNotifications.sender.email.$dirty || !this.modifiedNotifications.sender.email) return errors
      !this.$v.modifiedNotifications.sender.email.email && errors.push(this.$t('error.email'))
      return errors
    },
    hasEditedContent() {
      return JSON.stringify(this.modifiedNotifications) != JSON.stringify(this.initialNotifications)
    },
  },
  methods: {
    getTranslatableSet(recipient, part, set) {
      const notifications = clone(this.modifiedNotifications)
      if (!notifications[recipient][part][set]) {
        notifications[recipient][part][set] = Object.fromEntries(this.getLocales.map(tab => [tab, null]))
      }
      return { ...notifications[recipient][part][set] }
    },
    editContent(value, recipient, part, set) {
      this.modifiedNotifications[recipient][part][set] = value
    },
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    cancelEdit() {
      this.$emit('cancelEdit')
      this.modifiedNotifications = clone(this.initialNotifications)
      this.sectionKey++
    },
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('updateNotifications', this.modifiedNotifications)
        this.resetState()
      }
    },
    resetState() {
      this.currentlyEditingSubscribersNotification = false
      this.currentlyEditingObserversNotification = false
    },

    async uploadConsumerTemplate(files) {
      const filesContent = Object.fromEntries(
        await Promise.all(
          Object.entries(files).map(async ([locale, file]) => [locale, file !== null ? await file.text() : null])
        )
      )

      this.modifiedNotifications.consumer = { ...this.modifiedNotifications.consumer, template: filesContent }
    },
    async deleteConsumerTemplate(locale) {
      this.modifiedNotifications.consumer.template[locale] = null
    },
    cleanSettings(settings) {
      if (settings.sender.name === '') delete settings.sender.name
      if (settings.sender.email === '') delete settings.sender.email
      if (settings.internal.webhooks.newLead === '') settings.internal.webhooks.newLead = null
    },
  },
  validations() {
    return {
      modifiedNotifications: {
        internal: {
          webhooks: {
            newLead: {
              url,
            },
          },
        },
        sender: {
          email: {
            email,
          },
        },
      },
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style lang="scss" scoped>
.forms-individual-notifications {
  @apply tw-px-4 tw-mx-auto tw-py-8;
  max-width: 768px;
}
</style>
