<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="!form" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <UiContainer v-else large class="forms-individual tw-mt-8 tw-pb-24" :key="sectionKey">
      <form class="tw-flex tw-flex-col tw-gap-6" @submit.prevent="onSubmit" novalidate autocomplete="off">
        <UiCardForm class="tw-p-4 forms-individual__search">
          <div class="tw-flex tw-flex-col">
            <div class="tw-font-medium tw-text-base">
              {{ $t(`forms.individual.settings.search.lpeUrl.title`) }}
            </div>

            <div class="tw-text-xs tw-text-gray-500 tw-my-1">
              {{ $t(`forms.individual.settings.search.lpeUrl.description`) }}
            </div>

            <UiInputTextField
              v-model.trim="modifiedLocationSettings.lpeUrl"
              id="lpeUrl"
              :error-messages="lpeUrlErrors"
              :placeholder="$t('forms.individual.settings.search.lpeUrl.placeholder')"
            />
          </div>

          <div class="tw-flex tw-flex-col">
            <div class="tw-font-medium tw-text-base tw-pt-6">
              {{ $t(`forms.individual.settings.search.gmapKey.title`) }}
            </div>

            <div class="tw-text-xs tw-text-gray-500 tw-my-1">
              {{ $t(`forms.individual.settings.search.gmapKey.description`) }}
            </div>

            <UiInputTextField
              v-model.trim="modifiedLocationSettings.gmapKey"
              id="gmapKey"
              :placeholder="$t('forms.individual.settings.search.gmapKey.placeholder')"
            />
          </div>

          <div class="tw-flex tw-flex-col">
            <div class="tw-font-medium tw-text-base tw-pt-6">
              {{ $t(`forms.individual.settings.search.algoliaIndex.title`) }}
            </div>

            <div class="tw-text-xs tw-text-gray-500 tw-my-1">
              {{ $t(`forms.individual.settings.search.algoliaIndex.description`) }}
            </div>

            <UiInputTextField
              v-model.trim="modifiedLocationSettings.algoliaIndex"
              id="algoliaIndex"
              :error-messages="algoliaIndexErrors"
              :placeholder="$t('forms.individual.settings.search.algoliaIndex.placeholder')"
            />
          </div>

          <div class="tw-flex tw-flex-col">
            <div class="tw-font-medium tw-text-base tw-pt-6">
              {{ $t(`forms.individual.settings.search.geosearchResultLimit.title`) }}
            </div>

            <div class="tw-text-xs tw-text-gray-500 tw-my-1">
              {{ $t(`forms.individual.settings.search.geosearchResultLimit.description`) }}
            </div>

            <UiInputTextField
              v-model.number="modifiedLocationSettings.geosearchResultLimit"
              id="geosearchResultLimit"
              type="number"
              :min="0"
              :placeholder="$t('forms.individual.settings.search.geosearchResultLimit.placeholder')"
            />
          </div>

          <div class="tw-flex tw-flex-col">
            <div class="tw-font-medium tw-text-base tw-pt-6">
              {{ $t(`forms.individual.settings.search.resultDisplay.title`) }}
            </div>

            <div class="tw-text-xs tw-text-gray-500 tw-my-1">
              {{ $t(`forms.individual.settings.search.resultDisplay.description`) }}
            </div>
          </div>

          <div class="tw-grid tw-grid-cols-3 tw-gap-4 tw-mt-4 tw-ml-4">
            <div>
              <UiLabel
                id="openingStatus"
                :label="$t('forms.individual.settings.search.resultDisplay.labels.openingStatus')"
              />

              <v-switch
                :label="switchLabelByState(modifiedLocationSettings.display.openingStatus)"
                class="forms-individual__resultDisplay__toggle tw-mt-0"
                v-model="modifiedLocationSettings.display.openingStatus"
                hide-details
              />
            </div>

            <div>
              <UiLabel
                id="locationType"
                :label="$t('forms.individual.settings.search.resultDisplay.labels.locationType')"
              />

              <v-switch
                :label="switchLabelByState(modifiedLocationSettings.display.locationType)"
                class="forms-individual__resultDisplay__toggle tw-mt-0"
                v-model="modifiedLocationSettings.display.locationType"
                hide-details
              />
            </div>

            <div>
              <UiLabel id="company" :label="$t('forms.individual.settings.search.resultDisplay.labels.company')" />

              <v-switch
                :label="switchLabelByState(modifiedLocationSettings.display.company)"
                class="forms-individual__resultDisplay__toggle tw-mt-0"
                v-model="modifiedLocationSettings.display.company"
                hide-details
              />
            </div>

            <div>
              <UiLabel
                id="availability"
                :label="$t('forms.individual.settings.search.resultDisplay.labels.availability')"
              />

              <v-switch
                :label="switchLabelByState(modifiedLocationSettings.display.availability)"
                class="forms-individual__resultDisplay__toggle tw-mt-0"
                v-model="modifiedLocationSettings.display.availability"
                hide-details
              />
            </div>

            <div>
              <UiLabel
                id="locatorUrl"
                :label="
                  $t('forms.individual.settings.search.resultDisplay.labels.locatorUrl', { ...currentFormLexicon })
                "
              />

              <v-switch
                :label="switchLabelByState(modifiedLocationSettings.display.locatorUrl)"
                class="forms-individual__resultDisplay__toggle tw-mt-0"
                v-model="modifiedLocationSettings.display.locatorUrl"
                hide-details
              />
            </div>
          </div>
          <div>
            <div class="tw-font-medium tw-text-base tw-pt-6">
              {{ $t('forms.individual.settings.search.distanceUnit.title') }}
            </div>
            <v-select
              class="tw-font-normal tw-text-sm tw-mt-4"
              v-model="modifiedLocationSettings.distanceUnit"
              :items="distanceUnits"
              :error-messages="distanceUnitErrors"
              item-text="label"
              item-value="value"
              outlined
              dense
              clearable
              hide-details="auto"
            />
          </div>

          <UiInputToggle
            v-model="modifiedLocationSettings.autoGeolocated"
            class="forms-individual__resultDisplay__toggle tw-pt-6"
            :title="$t(`forms.individual.settings.search.autoGeolocation.title`)"
            :subtitle="$t(`forms.individual.settings.search.autoGeolocation.description`)"
          />
        </UiCardForm>

        <PlatformFormsIndividualUIEditActions v-if="hasEditedContent" type="submit" @cancelEdit="cancelEdit" />
      </form>
    </UiContainer>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import UiContainer from '@/components/UI/Container.vue'
import PlatformFormsIndividualUIEditActions from '@/components/Forms/Individual/UI/EditActions.vue'
import UiCardForm from '@/components/UI/CardForm.vue'
import UiLabel from '@/components/UI/Label.vue'
import { formatedDate } from '@/utils/date.util'
import UiInputTextField from '@/components/UI/input/InputTextField.vue'
import UiInputToggle from '@/components/UI/input/InputToggle.vue'
import { clone } from '@/utils/utilities.util'
import {
  mdiContentCopy,
  mdiContentSaveCheckOutline,
  mdiCloseCircle,
  mdiPlaylistEdit,
  mdiPlus,
  mdiAlertBox,
  mdiPencilCircleOutline,
} from '@mdi/js'
import { validationMixin } from 'vuelidate'
import { url, required } from 'vuelidate/lib/validators'
import { currencies } from '@/config/currencies.config'

export default {
  name: 'PlatformFormsIndividualLocationSettings',
  components: {
    UiContainer,
    PlatformFormsIndividualUIEditActions,
    UiLabel,
    UiCardForm,
    UiInputTextField,
    UiInputToggle,
  },

  props: {
    form: {
      type: Object,
      required: true,
    },
    updating: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [validationMixin],
  data: () => ({
    formatedDate,
    sectionKey: 0,
    modifiedLocationSettings: {
      gmapKey: '',
      algoliaIndex: '',
      display: {
        company: false,
        locationType: false,
        openingStatus: false,
      },
      autoGeolocated: false,
    },
    initialSettings: null,
    icons: {
      mdiContentCopy,
      mdiContentSaveCheckOutline,
      mdiCloseCircle,
      mdiPlaylistEdit,
      mdiPlus,
      mdiAlertBox,
      mdiPencilCircleOutline,
    },
    currencies,
  }),
  watch: {
    modifiedLocationSettings: {
      handler() {
        this.$emit('contentModified', this.hasEditedContent, 'locationSettings')
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    this.modifiedLocationSettings = {
      ...this.modifiedLocationSettings,
      ...this.form.locationSettings,
      display: {
        ...this.modifiedLocationSettings.display,
        ...this.form.locationSettings.display,
      },
    }
    this.initialSettings = clone(this.modifiedLocationSettings)
  },

  computed: {
    ...mapState({
      currentFormLexicon: state => state.leads.currentFormLexicon,
    }),
    lpeUrlErrors() {
      const errors = []
      if (!this.$v.modifiedLocationSettings.lpeUrl.$dirty) return errors
      !this.$v.modifiedLocationSettings.lpeUrl.url && errors.push(this.$t('error.url'))
      return errors
    },
    algoliaIndexErrors() {
      const errors = []
      if (!this.$v.modifiedLocationSettings.algoliaIndex.$dirty) return errors
      !this.$v.modifiedLocationSettings.algoliaIndex.required && errors.push(this.$t('error.required'))
      return errors
    },
    distanceUnitErrors() {
      const errors = []
      if (!this.$v.modifiedLocationSettings.distanceUnit.$dirty) return errors
      !this.$v.modifiedLocationSettings.distanceUnit.required && errors.push(this.$t('error.required'))
      return errors
    },
    distanceUnits() {
      return [
        {
          label: this.$t(`forms.individual.settings.search.distanceUnit.units.km`),
          value: 'km',
        },
        {
          label: this.$t(`forms.individual.settings.search.distanceUnit.units.mi`),
          value: 'mi',
        },
      ]
    },
    hasEditedContent() {
      return JSON.stringify(this.modifiedLocationSettings) != JSON.stringify(this.initialSettings)
    },
  },

  methods: {
    ...mapActions({
      setAlert: 'backoffice/setAlert',
    }),

    switchLabelByState(state) {
      return state === true ? this.$t('label.toggle.on') : this.$t('label.toggle.off')
    },

    cancelEdit() {
      this.modifiedLocationSettings = {
        ...this.initialSettings,
      }
      this.sectionKey++
    },

    onSubmit() {
      if (!this.$v.$invalid) {
        this.$emit('updateLocationSettings', this.modifiedLocationSettings)
        this.resetState()
      }
    },

    resetState() {
      this.disclaimer = false
    },
  },
  validations() {
    return {
      modifiedLocationSettings: {
        lpeUrl: {
          url,
        },
        algoliaIndex: {
          required,
        },
        distanceUnit: {
          required,
        },
      },
    }
  },
}
</script>

<style lang="scss">
.forms-individual {
  &__resultDisplay {
    &__toggle {
      .v-input--selection-controls__input {
        margin-right: 12px;
      }
    }
  }
}
</style>
