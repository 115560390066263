<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="!fields || !statics" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <UiContainer v-else large :key="sectionKey" class="tw-pb-24">
      <form class="tw-flex tw-flex-col tw-gap-6 form-fields" @submit.prevent="onSubmit" novalidate autocomplete="off">
        <!-- Fields configuration -->
        <UiCardForm flat>
          <div class="tw-p-6 tw-pb-0">
            <div class="tw-font-semibold tw-text-lg">
              {{ $t('forms.individual.fields.title') }}
            </div>
            <div class="tw-text-xs tw-text-gray-500 tw-my-1">
              {{ $t('forms.individual.fields.subtitle') }}
            </div>
          </div>

          <div class="tw-mt-6 tw-gap-4">
            <v-expansion-panels accordion multiple flat>
              <v-expansion-panel v-for="field in fields" :key="field.slug" class="form-fields__fields__element">
                <v-expansion-panel-header class="tw-py-5">
                  <div class="tw-font-medium">
                    {{ field.name }}
                  </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <FormsIndividualLocalizedInput
                    v-if="field.label"
                    class="tw-pb-5"
                    :defaultContent="field.label || {}"
                    :title="$t(`forms.individual.fields.labels.label`)"
                    @input="$event => updateFields(field.slug, 'label', $event)"
                  />
                  <FormsIndividualLocalizedInput
                    v-if="field.placeholder"
                    class="tw-pb-5"
                    :defaultContent="field.placeholder || {}"
                    :title="$t(`forms.individual.fields.labels.placeholder`)"
                    @input="$event => updateFields(field.slug, 'placeholder', $event)"
                  />
                  <FormsIndividualLocalizedInput
                    v-if="field.helper"
                    class="tw-pb-5"
                    :defaultContent="field.helper || {}"
                    :title="$t(`forms.individual.fields.labels.helper`)"
                    @input="$event => updateFields(field.slug, 'helper', $event)"
                  />
                  <UiInputTabbedList
                    v-if="field.options"
                    :tabs="getLocales"
                    :currentTab="currentLocaleTab"
                    :defaultContent="getFieldsOptions(field)"
                    :placeholder="$t('forms.individual.fields.optionPlaceholder')"
                    title="options"
                    @changeTab="$event => changeTab($event)"
                    @input="$event => updateOptions(field.slug, $event)"
                  ></UiInputTabbedList>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </UiCardForm>

        <!-- Statics configuration -->
        <UiCardForm flat>
          <div class="tw-p-6 tw-pb-0">
            <div class="tw-font-semibold tw-text-lg">
              {{ $t('forms.individual.fields.staticTitle') }}
            </div>
            <div class="tw-text-xs tw-text-gray-500 tw-my-1">
              {{ $t('forms.individual.fields.staticSubtitle') }}
            </div>
          </div>

          <div class="tw-mt-6 tw-gap-4">
            <v-expansion-panels accordion multiple flat>
              <v-expansion-panel
                v-for="staticElement in statics"
                :key="staticElement.slug"
                class="form-fields__statics__element"
              >
                <v-expansion-panel-header class="tw-py-5">
                  <div class="tw-font-medium">
                    {{ staticElement.slug }}
                  </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <FormsIndividualLocalizedInput
                    class="tw-pb-5"
                    :defaultContent="staticElement.value || {}"
                    :title="$t(`forms.individual.fields.labels.text`)"
                    :markdownEditor="staticElement.type === 'markdown'"
                    @input="$event => updateStatics(staticElement.slug, $event)"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </UiCardForm>

        <PlatformFormsIndividualUIEditActions v-if="hasEditedContent" type="submit" @cancelEdit="cancelEdit" />
      </form>
    </UiContainer>
  </v-fade-transition>
</template>

<script>
import { mapActions } from 'vuex'
import UiContainer from '@/components/UI/Container.vue'
import PlatformFormsIndividualUIEditActions from '@/components/Forms/Individual/UI/EditActions.vue'
import UiCardForm from '@/components/UI/CardForm.vue'
import { mdiContentCopy } from '@mdi/js'
import { clone } from '@/utils/utilities.util'
import FormsIndividualLocalizedInput from '@/components/Forms/Individual/LocalizedInput.vue'
import UiInputTabbedList from '@/components/UI/input/TabbedList.vue'
import { mapState } from 'vuex'
import { isEmpty } from '@/utils/helper.util'
import '@/plugins/markdown'

export default {
  name: 'PlatformFormsIndividualFields',
  components: {
    UiContainer,
    UiCardForm,
    UiInputTabbedList,
    PlatformFormsIndividualUIEditActions,
    FormsIndividualLocalizedInput,
  },

  props: {
    fields: {
      type: Array,
      required: true,
    },
    statics: {
      type: Array,
      required: true,
    },
    updating: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    sectionKey: 0,
    icons: {
      mdiContentCopy,
    },
    modifiedFields: [],
    initialFields: [],
    modifiedStatics: [],
    initialStatics: [],
  }),
  computed: {
    ...mapState({
      currentForm: state => state.leads.currentForm,
      currentFormFields: state => state.leads.currentFormFields,
      currentFormStatics: state => state.leads.currentFormStatics,
      currentLocaleTab: state => state.leads.currentLocaleTab,
    }),
    getLocales() {
      return this.currentForm?.translations?.activatedLocales || []
    },
    hasEditedFields() {
      return JSON.stringify(this.modifiedFields || []) != JSON.stringify(this.initialFields || [])
    },
    hasEditedStatics() {
      return JSON.stringify(this.modifiedStatics || []) != JSON.stringify(this.initialStatics || [])
    },

    hasEditedContent() {
      return this.hasEditedStatics || this.hasEditedFields
    },
  },
  watch: {
    modifiedFields: {
      // TODO: TO CHANGE field name
      handler() {
        this.$emit('contentModified', this.hasEditedContent, 'fields')
      },
      deep: true,
      immediate: false,
    },
    modifiedStatics: {
      // TODO: TO CHANGE field name
      handler() {
        this.$emit('contentModified', this.hasEditedContent, 'fields')
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    this.initFieldsAndStatics()
  },
  methods: {
    ...mapActions({
      setAlert: 'backoffice/setAlert',
      setCurrentLocaleTab: 'leads/setCurrentLocaleTab',
      setCurrentFormsFields: 'leads/setCurrentFormsFields',
    }),
    async changeTab(tab) {
      await this.setCurrentLocaleTab(tab)
    },
    async updateFields(slug, attribute, update) {
      const modifiedIndex = this.modifiedFields.findIndex(field => field.slug === slug)
      this.modifiedFields[modifiedIndex][attribute] = update
    },
    async updateOptions(slug, options) {
      const modifiedIndex = this.modifiedFields.findIndex(field => field.slug === slug)
      this.modifiedFields[modifiedIndex].options = options.map(option => ({ label: option.label, value: option.key }))
    },
    async updateStatics(slug, update) {
      const modifiedIndex = this.modifiedStatics.findIndex(staticElement => staticElement.slug === slug)
      this.modifiedStatics[modifiedIndex].value = update
    },

    initFieldsAndStatics() {
      this.modifiedFields = clone(this.currentFormFields)
      this.initialFields = clone(this.currentFormFields)

      this.modifiedStatics = clone(this.currentFormStatics)
      this.initialStatics = clone(this.currentFormStatics)
    },

    cancelEdit() {
      this.initFieldsAndStatics()
      this.sectionKey++
    },

    onSubmit() {
      this.disclaimer = false
      const updatedObject = {
        fields: [],
        statics: [],
      }

      if (this.hasEditedFields) {
        updatedObject.fields = this.modifiedFields
      }
      if (this.hasEditedStatics) {
        updatedObject.statics = this.modifiedStatics
      }

      this.$emit('update', updatedObject)
      this.resetState()
    },

    resetState() {
      this.initFieldsAndStatics()
    },

    getFieldsOptions(field) {
      if (isEmpty(field.options)) {
        return []
      }

      return field.options.map(option => ({
        key: option.value,
        label: option.label,
      }))
    },
  },
}
</script>

<style lang="scss">
.form-fields {
  &__fields,
  &__statics {
    &__element {
      border-top: solid 1px rgb(229, 231, 235);
    }
    &__element:not(:last-child) {
      border-radius: 0px !important;
    }
  }

  &__markdown .v-md-editor__toolbar ul {
    padding-left: 0 !important;
  }
}
</style>
