<template>
  <div class="localized-input">
    <UiInputTabbedInput
      :tabs="getLocales"
      :currentTab="currentLocaleTab"
      :defaultContent="defaultContent"
      :title="title"
      :placeholder="placeholder"
      :disabled="disabled"
      :hasFile="hasFile"
      :required="required"
      :markdownEditor="markdownEditor"
      @changeTab="$event => changeTab($event)"
      @input="$event => $emit('input', $event)"
      @change="$event => $emit('change', $event)"
      @deleteFile="$event => $emit('deleteFile', $event)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import UiInputTabbedInput from '@/components/UI/input/TabbedInput.vue'

export default {
  name: 'FormsIndividualLocalizedInput',
  components: { UiInputTabbedInput },
  props: {
    defaultContent: {
      type: Object,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    markdownEditor: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasFile: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      setCurrentLocaleTab: 'leads/setCurrentLocaleTab',
    }),
    async changeTab(tab) {
      await this.setCurrentLocaleTab(tab)
    },
  },
  computed: {
    ...mapState({
      currentForm: state => state.leads.currentForm,
      currentLocaleTab: state => state.leads.currentLocaleTab,
    }),
    getLocales() {
      return this.currentForm?.translations?.activatedLocales || []
    },
  },
}
</script>
