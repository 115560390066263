<template>
  <v-card :class="{ 'tw-p-6': flat === false }">
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  name: 'UiCardForm',
  props: {
    flat: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
