<template>
  <div class="ui-sidebar" :class="{ 'theme--dark': $vuetify.theme.dark }">
    <v-list>
      <v-list-item v-for="(item, i) in menuItems" :key="i" :value="item" @click="clickListItem(item, i)">
        <v-list-item-title :class="`ui-sidebar__${item.class}`" v-text="$t(item.label)" />
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'FormsIndividualNotificationsSideBar',
  props: {
    parentRefs: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      menuItems: [
        { label: 'forms.individual.notifications.sender.title', anchor: 'sender', class: 'title' },
        { label: `forms.individual.notifications.consumer.title`, anchor: 'consumer', class: 'title' },
        {
          label: `forms.individual.notifications.consumer.newLead.title`,
          anchor: 'confirmation',
          class: 'subtitle',
        },
        {
          label: `forms.individual.notifications.consumer.acceptance.title`,
          anchor: 'acceptance',
          class: 'subtitle',
        },
        {
          label: 'forms.individual.notifications.consumer.followUp.title',
          anchor: 'consumerFollowUp',
          class: 'subtitle',
        },
        {
          label: `forms.individual.notifications.consumer.layout.title`,
          anchor: 'consumerLayout',
          class: 'subtitle',
        },
        { label: `forms.individual.notifications.internal.title`, anchor: 'administrators', class: 'title' },
        {
          label: `forms.individual.notifications.internal.configuration.title`,
          anchor: 'internalConfiguration',
          class: 'subtitle',
        },
        {
          label: `forms.individual.notifications.internal.newLeadSubscriber.title`,
          anchor: 'newLeadSubscriber',
          class: 'subtitle',
        },
        {
          label: `forms.individual.notifications.internal.newLeadObserver.title`,
          anchor: 'newLeadObserver',
          class: 'subtitle',
        },
        {
          label: `forms.individual.notifications.internal.reject.title`,
          anchor: 'internalReject',
          class: 'subtitle',
        },
        { label: 'forms.individual.notifications.forwarded.title', anchor: 'forwarded', class: 'subtitle' },
        {
          label: `forms.individual.notifications.internal.followUp.title`,
          anchor: 'internalFollowUp',
          class: 'subtitle',
        },
        {
          label: `forms.individual.notifications.internal.followUpObserver.title`,
          anchor: 'internalFollowUpObserver',
          class: 'subtitle',
        },
        { label: 'forms.individual.notifications.email.title', anchor: 'emailCustomization', class: 'title' },
      ],
      selectedItemIndex: null,
      scrollPosition: null,
    }
  },
  methods: {
    clickListItem(item, id) {
      this.selectedItemIndex = id

      const refName = item.anchor
      const el = this.parentRefs[refName]
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
      }
    },
  },
}
</script>

<style lang="scss">
.ui-sidebar {
  @apply tw-pt-10 tw-top-10 tw-w-60;
  position: fixed;
  right: 8px;
  bottom: 0;
  border-left: 1px solid map-deep-get($material-light, 'banner', 'border');
  background: map-get($material-light, 'banner', 'background');
  z-index: 0;

  &.theme--dark {
    border-left: 1px solid map-deep-get($material-dark, 'banner', 'border');
    background: map-get($material-dark, 'banner', 'background');
  }

  &__title {
    @apply tw-font-semibold tw-pr-4;
  }

  &__subtitle {
    @apply tw-pl-4;
  }

  .v-list-item {
    min-height: 37px;
  }
}
</style>
