<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="!notifications" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <UiContainer v-else class="forms-individual-notifications tw-pb-24" :large="currentTab === 'configuration'">
      <div class="forms-individual-notifications__items">
        <forms-individual-notifications-configurations
          :notifications="formNotifications"
          :is-located-form="isLocatedForm"
          :is-product-form="isProductForm"
          @updateNotifications="updateNotifications"
          @contentModified="contentModified"
          @cancelEdit="cancelEdit"
        />
      </div>
    </UiContainer>
  </v-fade-transition>
</template>

<script>
import { mapActions } from 'vuex'

import UiContainer from '@/components/UI/Container.vue'
import FormsIndividualNotificationsConfigurations from '@/components/Forms/Individual/Notifications/Configurations.vue'

export default {
  name: 'FormFormsIndividualNotifications',
  components: {
    UiContainer,
    FormsIndividualNotificationsConfigurations,
  },
  props: {
    notifications: {
      type: Object,
      required: true,
    },
    isLocatedForm: {
      type: Boolean,
      required: true,
    },
    isProductForm: {
      type: Boolean,
      required: true,
    },
    updating: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    currentTab: '',
    formNotifications: {
      consumer: {
        newLead: {
          subject: null,
          title: null,
        },
        followUp: {
          subject: null,
          title: null,
          delay: 14,
        },
        acceptance: {
          subject: null,
          title: null,
          content: null,
        },
        template: null,
      },
      internal: {
        locale: null,
        newLeadSubscriber: {
          emails: [],
          subject: null,
          title: null,
        },
        newLeadObserver: {
          emails: [],
          subject: null,
          title: null,
        },
        webhooks: {
          newLead: null,
        },
        followUp: {
          frequency: null,
          subject: null,
          acceptedTitle: null,
          unacceptedTitle: null,
        },
        forwardedLead: {
          title: null,
          subject: null,
        },
        reject: {
          title: null,
          subject: null,
        },
        followUpObserver: {
          frequency: null,
          subject: null,
          acceptedTitle: null,
          unacceptedTitle: null,
        },
      },
      layout: {
        logo: null,
        primaryColor: null,
        altTextColor: null,
        clientName: null,
      },
    },
  }),

  created() {
    const { locale, webhooks } = this.notifications.internal
    const { primaryColor, altTextColor, clientName, logo } = this.notifications.layout

    const { title: consumerNewLeadTitle, subject: consumerNewLeadSubject } = this.notifications.consumer?.newLead || {}
    const {
      title: consumerAcceptanceTitle,
      subject: consumerAcceptanceSubject,
      content: consumerAcceptanceContent,
    } = this.notifications.consumer?.acceptance || {}
    const consumerTemplate = this.notifications.consumer?.template || null

    const {
      emails: subscriberEmails,
      title: subscriberNewLeadTitle,
      subject: subscriberNewLeadSubject,
    } = this.notifications.internal.newLeadSubscriber || {}
    const {
      emails: observerEmails,
      title: observerNewLeadTitle,
      subject: observerNewLeadSubject,
    } = this.notifications.internal.newLeadObserver || {}

    const {
      active: internalFollowUpActive,
      frequency: internalFrequency,
      subject: internalFollowUpSubject,
      acceptedTitle: internalFollowUpAcceptedTitle,
      unacceptedTitle: internalFollowUpUnacceptedTitle,
    } = this.notifications.internal.followUp
    const { title: forwardedTitle, subject: forwardedSubject } = this.notifications.internal.forwardedLead || {}

    const {
      tag: displayTag,
      sku: displaySku,
      location: displayLocation,
      company: displayCompany,
    } = this.notifications.internal.display || {}
    const { name: senderName, email: senderEmail } = this.notifications.sender || {}
    const {
      active: consumerFollowUpActive,
      subject: consumerFollowUpSubject,
      title: consumerFollowUpTitle,
      delay: consumerFollowUpDelay,
    } = this.notifications.consumer.followUp || {}
    const { location: displayLocationConsumer, company: displayCompanyConsumer } =
      this.notifications.consumer.display || {}
    const {
      active: internalRejectActive,
      title: internalRejectTitle,
      subject: internalRejectSubject,
    } = this.notifications.internal.reject || {}
    const {
      active: internalFollowUpObserverActive,
      frequency: internalFollowUpObserverFrequency,
      subject: internalFollowUpObserverSubject,
      acceptedTitle: internalFollowUpObserverAcceptedTitle,
      unacceptedTitle: internalFollowUpObserverUnacceptedTitle,
    } = this.notifications.internal.followUpObserver || {}

    this.formNotifications = {
      internal: {
        ...this.formNotifications.internal,
        locale,
        newLeadSubscriber: {
          ...(subscriberEmails && { emails: subscriberEmails }),
          ...(subscriberNewLeadSubject && { subject: subscriberNewLeadSubject }),
          ...(subscriberNewLeadTitle && { title: subscriberNewLeadTitle }),
        },
        newLeadObserver: {
          ...(observerEmails && { emails: observerEmails }),
          ...(observerNewLeadSubject && { subject: observerNewLeadSubject }),
          ...(observerNewLeadTitle && { title: observerNewLeadTitle }),
        },
        webhooks: {
          ...this.formNotifications.internal.webhooks,
          ...(webhooks?.newLead && { newLead: webhooks.newLead }),
        },
        followUp: {
          ...this.formNotifications.internal.followUp,
          ...(typeof internalFollowUpActive === 'boolean' && { active: internalFollowUpActive }),
          ...(internalFrequency && { frequency: internalFrequency }),
          ...(internalFollowUpSubject && { subject: internalFollowUpSubject }),
          ...(internalFollowUpAcceptedTitle && { acceptedTitle: internalFollowUpAcceptedTitle }),
          ...(internalFollowUpUnacceptedTitle && { unacceptedTitle: internalFollowUpUnacceptedTitle }),
        },
        forwardedLead: {
          ...this.formNotifications.forwardedLead,
          ...(forwardedSubject && { subject: forwardedSubject }),
          ...(forwardedTitle && { title: forwardedTitle }),
        },
        display: {
          ...this.formNotifications.display,
          ...(typeof displayTag === 'boolean' && { tag: displayTag }),
          ...(typeof displaySku === 'boolean' && { sku: displaySku }),
          ...(typeof displayLocation === 'boolean' && { location: displayLocation }),
          ...(typeof displayCompany === 'boolean' && { company: displayCompany }),
        },
        reject: {
          ...this.formNotifications.reject,
          ...(typeof internalRejectActive === 'boolean' && { active: internalRejectActive }),
          ...(internalRejectSubject && { subject: internalRejectSubject }),
          ...(internalRejectTitle && { title: internalRejectTitle }),
        },
        followUpObserver: {
          ...this.formNotifications.internal.followUpObserver,
          ...(typeof internalFollowUpObserverActive === 'boolean' && { active: internalFollowUpObserverActive }),
          ...(internalFollowUpObserverFrequency && { frequency: internalFollowUpObserverFrequency }),
          ...(internalFollowUpObserverSubject && { subject: internalFollowUpObserverSubject }),
          ...(internalFollowUpObserverAcceptedTitle && { acceptedTitle: internalFollowUpObserverAcceptedTitle }),
          ...(internalFollowUpObserverUnacceptedTitle && { unacceptedTitle: internalFollowUpObserverUnacceptedTitle }),
        },
      },
      consumer: {
        display: {
          ...(typeof displayLocation === 'boolean' && { location: displayLocationConsumer }),
          ...(typeof displayCompany === 'boolean' && { company: displayCompanyConsumer }),
        },
        newLead: {
          ...this.formNotifications.consumer.newLead,
          ...(consumerNewLeadTitle && { title: consumerNewLeadTitle }),
          ...(consumerNewLeadSubject && { subject: consumerNewLeadSubject }),
        },
        followUp: {
          ...this.formNotifications.consumer.followUp,
          ...(typeof consumerFollowUpActive === 'boolean' && { active: consumerFollowUpActive }),
          ...(consumerFollowUpSubject && { subject: consumerFollowUpSubject }),
          ...(consumerFollowUpTitle && { title: consumerFollowUpTitle }),
          ...(consumerFollowUpDelay && { delay: consumerFollowUpDelay }),
        },
        acceptance: {
          ...this.formNotifications.consumer.acceptance,
          ...(consumerAcceptanceTitle && { title: consumerAcceptanceTitle }),
          ...(consumerAcceptanceSubject && { subject: consumerAcceptanceSubject }),
          ...(consumerAcceptanceContent && { content: consumerAcceptanceContent }),
        },
        ...(consumerTemplate && { template: consumerTemplate }),
      },
      layout: {
        ...this.formNotifications.layout,
        ...(primaryColor && { primaryColor }),
        ...(altTextColor && { altTextColor }),
        ...(clientName && { clientName }),
        ...(logo && { logo }),
      },
      sender: {
        ...this.formNotifications.sender,
        ...(senderName && { name: senderName }),
        ...(senderEmail && { email: senderEmail }),
      },
    }
  },
  computed: {
    tabs() {
      return [
        {
          label: this.$t('forms.individual.notifications.tabs.configuration'),
          value: 'configuration',
        },
        {
          label: this.$t('forms.individual.notifications.tabs.emailCustomization'),
          value: 'emailCustomization',
        },
      ]
    },
    logoUrl() {
      return this.notifications.layout.logo?.url || null
    },
  },
  methods: {
    ...mapActions({
      setAlert: 'backoffice/setAlert',
    }),

    cancelEdit() {
      this.$emit('contentModified', false, 'notifications')
    },

    contentModified(bool, string) {
      this.$emit('contentModified', bool, string)
    },
    updateNotifications(params) {
      this.$emit('updateNotifications', params)
    },
  },
}
</script>

<style lang="scss" scoped>
.forms-individual-notifications {
  &__tabs {
    @apply tw-mx-auto tw-rounded-md;
    width: fit-content;

    &--active {
      @apply tw-rounded-md;
      background-color: $blue-dodger;
      color: $white !important;
    }

    &__tab {
      @apply tw-px-8;
      width: 240px !important;
    }
  }

  &__items {
    @apply tw-mt-8 tw-px-2;
    background: none;
  }
}
</style>
